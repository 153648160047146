/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:03093aba-5723-49ac-98b0-2b414b2b10d2",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_AlwAe3jCM",
    "aws_user_pools_web_client_id": "2p928s8227gtdi8599hqimmn4q",
    "oauth": {},
    "aws_user_files_s3_bucket": "eu-ccp-excel-bucket82205-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
