const FORM_FIELDS = [
    {
        id: 'donation_id',
        order: 1,
        required: true,
        type: 'text',
        label: "What is the unique identifier of the donation?",
        name: "donation_id",
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        visible: true,
        depends: undefined,
        choices: undefined,
    },
    {
        id: 'donation_date',
        order: 2,
        required: false,
        type: 'date',
        label: "What was the date of donation?",
        name: "donation_date",
        helperText: "Please provide a past date",
        InputLabelProps: {
             shrink: true 
            },
        visible: true,
        depends: undefined,
        choices: undefined
    },
    {
        id: 'donation_plasma_collection_method',
        order: 3,
        required: false,
        type: 'select',
        label: "How was plasma collected?",
        name: "donation_plasma_collection_method",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "apheresis",
                label: 'apheresis'
            },
            {
                value: "whole blood",
                label: 'whole blood'
            }
        ]
    },
    {
        id: 'donation_donor_age',
        order: 4,
        required: false,
        type: 'select',
        label: "What was the age of the donor at the moment of donation?",
        name: "donation_donor_age",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "10-20 years old",
                label: '10-20 years old'
            },
            {
                value: "21-30 years old",
                label: '21-30 years old'
            },
            {
                value: "31-40 years old",
                label: '31-40 years old'
            },
            {
                value: "41-50 years old",
                label: '41-50 years old'
            },
            {
                value: "51-60 years old",
                label: '51-60 years old'
            },
            {
                value: "61-70 years old",
                label: '61-70 years old'
            },
            {
                value: "71-80 years old",
                label: '71-80 years old'
            },
            {
                value: ">80 years old",
                label: '>80 years old'
            }
        ]
    },
    {
        id: 'donation_donor_bmi',
        order: 5,
        required: false,
        type: 'select',
        label: "What was the BMI of the donor at the moment of donation?",
        name: "donation_donor_bmi",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "10-15",
                label: '10-15'
            },
            {
                value: "15-20",
                label: '15-20'
            },
            {
                value: "20-25",
                label: '20-25'
            },
            {
                value: "25-30",
                label: '25-30'
            },
            {
                value: "30-35",
                label: '30-35'
            },
            {
                value: "35-40",
                label: '35-40'
            },
            {
                value: "40-45",
                label: '40-45'
            },
            {
                value: "45-50",
                label: '45-50'
            }
        ]
    },
    {
        id: 'donation_donor_gender',
        order: 6,
        required: false,
        type: 'select',
        label: "What is the sex of the donor?",
        name: "donation_donor_gender",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "Male",
                label: 'Male'
            },
            {
                value: "Female",
                label: 'Female'
            }
        ]
    },
    {
        id: 'donation_donor_vaccinated',
        order: 7,
        required: false,
        type: 'select',
        label: "Is the donor vaccinated?",
        name: "donation_donor_vaccinated",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "Yes",
                label: 'Yes'
            },
            {
                value: "No",
                label: 'No'
            },
            {
                value: "Not determined",
                label: 'Not determined'
            }
        ]
    },
    {
        id: 'donation_donor_vaccine',
        order: 8,
        required: false,
        type: 'select',
        label: "Type of vaccine used?",
        name: "donation_donor_vaccine",
        visible: false,
        depends: [
            {
                id: "donation_donor_vaccinated",
                answers: [
                    "Yes"
                ]
            }
        ],
        choices: [
            {
                value: "Pfizer-BioNTech",
                label: 'Pfizer-BioNTech'
            },
            {
                value: "Astra Zeneca",
                label: 'Astra Zeneca'
            },
            {
                value: "Moderna",
                label: 'Moderna'
            },
            {
                value: "Janssen ( Johnson and Johnson)",
                label: 'Janssen ( Johnson and Johnson)'
            },
            {
                value: "Janssen ( Johnson and Johnson)",
                label: 'Janssen ( Johnson and Johnson)'
            },
            {
                value: "Sanofi-GSK",
                label: 'Sanofi-GSK'
            }
        ]
    },
    {
        id: 'donation_donor_vaccination_date',
        order: 9,
        required: false,
        type: 'date',
        label: "Date of vaccination – 1st dose?",
        name: "donation_donor_vaccination_date",
        helperText: "Please provide a past date",
        InputLabelProps: {
             shrink: true 
            },
        visible: false,
        depends: [
            {
                id: "donation_donor_vaccinated",
                answers: [
                    "Yes"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'donation_interval_since_symptoms',
        order: 10,
        required: false,
        type: 'number',
        label: "Fill in the number of days between the end of COVID-19 symptoms in the donor and this donation?",
        name: "donation_interval_since_symptoms",
        visible: true,
        depends: undefined,
        choices: undefined
    },
    {
        id: 'donation_adverse_reaction_on_donor',
        order: 11,
        required: false,
        type: 'select',
        label: "Did an adverse reaction or event take place during plasma collection?",
        name: "donation_adverse_reaction_on_donor",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "yes",
                label: 'yes'
            },
            {
                value: "no",
                label: 'no'
            },
            {
                value: "not yet available",
                label: 'not yet available'
            }
        ]
    },
    {
        id: 'donation_adverse_reaction_description',
        order: 12,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        label: "If yes, provide a description of the adverse reaction:",
        name: "donation_adverse_reaction_description",
        visible: false,
        depends: [
            {
                id: "donation_adverse_reaction_on_donor",
                answers: [
                    "yes"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'donation_adverse_reaction_timing',
        order: 13,
        required: false,
        type: 'select',
        label: "If yes, when did the adverse reaction take place?",
        name: "donation_adverse_reaction_timing",
        visible: false,
        depends: [
            {
                id: "donation_adverse_reaction_on_donor",
                answers: [
                    "yes"
                ]
            }
        ],
        choices: [
            {
                value: "during the donation",
                label: 'during the donation'
            },
            {
                value: "after the donation",
                label: 'after the donation'
            }
        ]
    },
    {
        id: 'donation_adverse_reaction_severity',
        required: false,
        type: 'select',
        label: "What was the severity grade of the adverse reaction?",
        name: "donation_adverse_reaction_severity",
        visible: false,
        depends: [
            {
                id: "donation_adverse_reaction_on_donor",
                answers: [
                    "yes"
                ]
            }
        ],
        choices: [
            {
                value: "1) minor",
                label: '1) minor'
            },
            {
                value: "2) moderate",
                label: '2) moderate'
            },
            {
                value: "3) severe",
                label: '3) severe'
            },
            {
                value: "4) death",
                label: '4) death'
            }
        ]

    },
    {
        id: 'donation_adverse_reaction_imputability',
        required: false,
        type: 'select',
        label: "What is the imputability of the adverse reaction to the donation?",
        name: "donation_adverse_reaction_imputability",
        visible: false,
        depends: [
            {
                id: "donation_adverse_reaction_on_donor",
                answers: [
                    "yes"
                ]
            }
        ],
        choices: [
            {
                value: "Imputability not assessable",
                label: 'Imputability not assessable'
            },
            {
                value: "Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the donation",
                label: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the donation'
            },
            {
                value: "Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the donation",
                label: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the donation'
            },
            {
                value: "Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the donation",
                label: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the donation'
            },
            {
                value: "Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the donation",
                label: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the donation'
            }
        ]

    },
    {
        id: 'donation_plasma_volume',
        required: false,
        type: 'number',
        label: "What is the volume of the collected plasma? (ml)",
        name: "donation_plasma_volume",
        helperText: "integer between 0 and 1500",
        visible: true,
        depends: undefined,
        choices: undefined
    },
    {
        id: 'donation_plasma_pathogen_reduction',
        required: false,
        type: 'select',
        label: "Was a procedure of Pathogen Reduction (PR) performed on this product?",
        name: "donation_plasma_pathogen_reduction",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'donation_plasma_quarantined',
        required: false,
        type: 'select',
        label: "Is the plasma secured by quarantine?",
        name: "donation_plasma_quarantined",
        visible: false,
        depends: [
            {
                id: "donation_plasma_pathogen_reduction",
                answers: [
                    "False"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'donation_splitted_in_multiple_units',
        required: false,
        type: 'select',
        label: "Were multiple units created from this one single donation before storage?",
        name: "donation_splitted_in_multiple_units",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'donation_number_plasma_units',
        required: false,
        type: 'select',
        label: "How many plasma units were made available?",
        name: "donation_number_plasma_units",
        visible: false,
        depends: [
            {
                id: "donation_splitted_in_multiple_units",
                answers: [
                    "True"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            }
        ]
    },
    {
        id: 'donation_known_plasma_unit_mean_volume',
        required: false,
        type: 'select',
        label: "is the mean volume per unit already known?",
        name: "donation_known_plasma_unit_mean_volume",
        visible: false,
        depends: [
            {
                id: "donation_splitted_in_multiple_units",
                answers: [
                    "True"
                ]
                
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'not yet available'
            }
        ]
    },
    {
        id: 'donation_plasma_unit_mean_volume',
        required: false,
        type: 'number',
        label: "What is the mean volume per unit? (ml)",
        name: "donation_plasma_unit_mean_volume",
        helperText: "integer between 100 and 500",
        visible: false,
        depends: [
            {
                id: "donation_known_plasma_unit_mean_volume",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'donation_tested_elisa',
        required: false,
        type: 'select',
        label: "Was this product tested for the presence of anti-SARS-CoV2 antibodies by Elisa?",
        name: "donation_tested_elisa",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "yes",
                label: 'yes'
            },
            {
                value: "no",
                label: 'no'
            },
            {
                value: "not yet available",
                label: 'not yet available'
            }
        ]
    },
    {
        id: 'donation_elisa_testing_assay',
        required: false,
        type: 'select',
        label: "Please specify the commercial name of the ELISA Testing ASSAY",
        name: "donation_elisa_testing_assay",
        visible: true,
        depends: [
            {
                id: "donation_tested_elisa",
                answers: [
                    "yes"
                ]
            }
        ],
        choices: [
            {
                value: "Abbott- Sars-CoV2 IgG ( Architect & Alinityi)",
                label: 'Abbott- Sars-CoV2 IgG ( Architect & Alinityi)'
            },
            {
                value: "Beckman Couletr – Access  Sars-CoV2 IgG",
                label: 'Beckman Couletr – Access  Sars-CoV2 IgG'
            },
            {
                value: "Euroimmun – Anti-SARS-CoV2 ELISA (IgG)",
                label: 'Euroimmun – Anti-SARS-CoV2 ELISA (IgG)'
            },
            {
                value: "GenScript – cPass SARS-CoV2 Neutralization Antibody Detection Kit",
                label: 'GenScript – cPass SARS-CoV2 Neutralization Antibody Detection Kit'
            },
            {
                value: "Kantaro – COVID SeroKlir",
                label: 'Kantaro – COVID SeroKlir'
            },
            {
                value: "Mount Sinai – Covid-19 ELISA IgG",
                label: 'Mount Sinai – Covid-19 ELISA IgG'
            },
            {
                value: "Ortho – VITROS Anti- Sars-CoV2 IgG",
                label: 'Ortho – VITROS Anti- Sars-CoV2 IgG'
            },
            {
                value: "Roche – Elecsys Anti-SARS-CoV2 S",
                label: 'Roche – Elecsys Anti-SARS-CoV2 S'
            },
            {
                value: "Siemens – ADVIA Centaur Sars-CoV2 IgG (Cov2G)",
                label: 'Siemens – ADVIA Centaur Sars-CoV2 IgG (Cov2G)'
            },
            {
                value: "Snibe Maglumi SARS-CoV-2 RBD IgG",
                label: 'Snibe Maglumi SARS-CoV-2 RBD IgG'
            }
        ]
    },
    {
        id: 'donation_elisa_testing_assay_value',
        required: false,
        type: 'text',
        label: "What is the value of the ELISA assay?",
        name: "donation_elisa_testing_assay_value",
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        visible: true,
        depends: [
            {
                id: "donation_tested_elisa",
                answers: [
                    "yes"
                ]
            }
        ],
        choices: undefined,
    },
    {
        id: 'donation_elisa_test_result',
        required: false,
        type: 'select',
        label: "What was the result of the Elisa test?",
        name: "donation_elisa_test_result",
        visible: false,
        depends: [
            {
                id: "donation_tested_elisa",
                answers: [
                    "yes"
                ]
            }
        ],
        choices: [
            {
                value: "positive",
                label: 'positive'
            },
            {
                value: "negative",
                label: 'negative'
            }
        ]
    },
    {
        id: 'donation_elisa_titer_conclusion',
        required: false,
        type: 'select',
        label: "What is the titer conclusion based on ELISA?",
        name: "donation_elisa_titer_conclusion",
        visible: false,
        depends: [
            {
                id: "donation_elisa_test_result",
                answers: [
                    "positive"
                ]
            }
        ],
        choices: [
            {
                value: "Low titer",
                label: 'Low titer'
            },
            {
                value: "High titer",
                label: 'High titer'
            }
        ]
    },
    {
        id: 'donation_has_neutralizing_titer',
        required: false,
        type: 'select',
        label: "Was this product tested for the presence of neutralizing anti-SARS-CoV2 antibodies?",
        name: "donation_has_neutralizing_titer",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'donation_neutralizing_titer_value',
        required: false,
        type: 'number',
        label: "what is the value of the neutralizing titer?",
        name: "donation_neutralizing_titer_value",
        helperText: "integer between 0 and 5000",
        visible: false,
        depends: [
            {
                id: "donation_has_neutralizing_titer",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'donation_rtpcr_result',
        required: false,
        type: 'select',
        label: "What was the result of the RT PCR on the product?",
        name: "donation_rtpcr_result",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "positive",
                label: 'positive'
            },
            {
                value: "negative",
                label: 'negative'
            },
            {
                value: "not yet available",
                label: 'not yet available'
            },
            {
                value: "the donation was not tested",
                label: 'the donation was not tested'
            }
        ]
    },
    {
        id: 'donation_plasma_conformity',
        required: false,
        type: 'select',
        label: "Was the plasma suitable for COVID-19 treatment according to the requirements described in your protocol?",
        name: "donation_plasma_conformity",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "yes",
                label: 'yes'
            },
            {
                value: "no",
                label: 'no'
            },
            {
                value: "not yet available",
                label: 'not yet available'
            }
        ]
    },
    {
        id: 'donation_plasma_intended_use',
        required: false,
        type: 'select',
        label: "What is the intended use of the plasma?",
        name: "donation_plasma_intended_use",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "To be used for treatment on Covid patients",
                label: 'To be used for treatment on Covid patients'
            },
            {
                value: "To be used for production of CIVID specific hyperimmune gammaglobulins (PDMP)",
                label: 'To be used for production of CIVID specific hyperimmune gammaglobulins (PDMP)'
            },
            {
                value: "To be used in the general setting of standard FFP",
                label: 'To be used in the general setting of standard FFP'
            },
            {
                value: "To be discarded or research",
                label: 'To be discarded or research'
            },
            {
                value: "Not yet determined.",
                label: 'Not yet determined.'
            }
        ]
    },
    {
        id: 'donation_plasma_non_conformity_reason',
        required: false,
        type: 'select',
        label: "What was the reason for the non-conformity?",
        name: "donation_plasma_non_conformity_reason",
        visible: false,
        depends: [
            {
                id: "donation_plasma_conformity",
                answers: [
                    "no"
                ]
            }
        ],
        choices: [
            {
                value: "post donation information",
                label: 'post donation information'
            },
            {
                value: "(neutralizing) antibody titer too low",
                label: '(neutralizing) antibody titer too low'
            },
            {
                value: "positive RT PCR",
                label: 'positive RT PCR'
            },
            {
                value: "non compliance of donor",
                label: 'non compliance of donor'
            },
            {
                value: "biological qualification",
                label: 'biological qualification'
            },
            {
                value: "other non conformity",
                label: 'other non conformity'
            }
        ]
    },
    {
        id: 'donation_plasma_non_conformity_reason_if_other',
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        label: "Please specify the reason of the non-conformity:",
        name: "donation_plasma_non_conformity_reason_if_other",
        visible: false,
        depends: [
            {
                id: "donation_plasma_non_conformity_reason",
                answers: [
                    "other non conformity"
                ]
            },
            {
                id: "donation_plasma_conformity",
                answers: [
                    "no"
                ]
            }
        ],
        choices: undefined
    }
]

export default FORM_FIELDS