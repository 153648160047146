import React, { Component } from 'react';

import CustomSignIn from '../CustomSignIn'
import CustomApp from '../CustomApp'

export default class CustomAuthWrapper extends Component {


    constructor(props) {
        super(props);

        this.state = {
            passwordChange: false
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this)
    }

    handlePasswordChange = function(flag) {
           this.setState({passwordChange: flag});
    }

    render() {

        return (
            <div>
                {
                    this.props.authState === "signedIn" && this.state.passwordChange === false ?
                        <CustomApp />
                    :
                        <CustomSignIn 
                            authState={this.props.authState}
                            onStateChange={this.props.onStateChange}
                            onPasswordChange={this.handlePasswordChange}
                        />
                }
            </div>
        )
    
    }
}