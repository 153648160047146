import { API } from 'aws-amplify';

const postData = async (inputPath, payload) => { 
    const apiName = process.env.REACT_APP_AMPLIFY_NAME;
    const path = inputPath;
    const myInit = {
        body: payload,
        headers: {},
    };
    const result = await API.post(apiName, path, myInit);
    return result;
}

const fetchData = async (inputPath) => {
    const apiName = process.env.REACT_APP_AMPLIFY_NAME;
    const path = inputPath;
    const result = await API.get(apiName, path);
    return result
};

export {
    postData,
    fetchData
}