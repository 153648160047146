const PATH = {
    test: {
        path: '/test/donations/add',
        search: '/test/donations/search',
        upload_history: '/test/donations/uploadhistory',
        patient: '/test/patients/add',
        patient_search: '/test/patients/search',
        patient_history: '/test/patients/uploadhistory',
        clinical_use: '/test/clinicaluse/add',
        clinical_use_search: '/test/clinicaluse/search',
        donation_extract: '/test/extract/donations'
    }

}

export default PATH