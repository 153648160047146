import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, FormLabel, FormControl, Checkbox } from '@material-ui/core';

export default class CustomMultiselect extends Component {

    constructor(props) {
        super()
        this.state = {
            choicesState: props.choices !== undefined ? props.choices : []
        }
    }

    handleChange = (event, index, id) => {
        event.preventDefault()
        let tempChoices = this.state.choicesState
        tempChoices[index].value = !tempChoices[index].value
        this.setState({
            choicesState: tempChoices
        }, this.props.onChange({type: 'multiselect', data: tempChoices, id: id, index: index}))
    }

    render() {

        return (
            <FormControl component="fieldset" >
                <FormLabel component="legend">{this.props.label}</FormLabel>
                <FormGroup>
                    {this.state.choicesState.map((elem,index) => (
                        <FormControlLabel
                            key={index}
                            control={<Checkbox checked={elem.value} onChange={event => this.handleChange(event, index, this.props.id)} name={elem.label} />}
                            label={elem.label}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        )
    }
}

CustomMultiselect.propTypes = {
    id: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
    choices: PropTypes.array,
    onChange: PropTypes.func,
};