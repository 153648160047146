import React, { Component } from 'react';
import { Auth } from "aws-amplify";

import './CustomSignIn.css';

Auth.configure({
    authenticationFlowType: 'USER_PASSWORD_AUTH',
})
export default class CustomSignIn extends Component {

    constructor(props) {
        super(props)

        this._validAuthStates = ["signIn", "signedOut", "signedUp"];

        this.state = {
            username: '',
            password: '',
            error: false,
            changePass: false,
            user: null
        }
        this.signIn = this.signIn.bind(this)
        this.changeInput = this.changeInput.bind(this)
        this.completeNewPassword = this.completeNewPassword.bind(this)
    }

    changeInput = (event) => {
        this.setState({
            [event.nativeEvent.target.id]: event.nativeEvent.target.value
        })
    }

    signIn = async (event) => {
        event.preventDefault()

        try {
            let response = await Auth.signIn(this.state.username, this.state.password);
            //console.log('response', JSON.stringify(response));
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await this.setState({
                    changePass: true,
                    user: response
                });
                this.props.onPasswordChange(true);
            }
            await this.setState({
                error: false
            });
            this.props.onStateChange("signedIn", {});
        } catch (err) {
            console.log("Error -", err);
            await this.setState({
                error: true
            });
        }
    }

    completeNewPassword = async (event) => {
        event.preventDefault();

        try {
            let response = await Auth.completeNewPassword(this.state.user, this.state.newpassword);
            await this.setState({
                changePass: false
            });
            this.props.onPasswordChange(false);

        } catch (err) {
            console.log("Error change password - ", err);
        }

    }

    render() {

        return (
            <>

                <header data-ecl-auto-init="SiteHeaderCore" className="ecl-site-header-core">
                    <div className="ecl-site-header-core__container ecl-container">
                        <div className="ecl-site-header-core__top">
                            <a className="ecl-link ecl-link--standalone ecl-site-header-core__logo-link" 
                            href="https://ec.europa.eu/info/index_en" aria-label="Home (European Commission)">
                                <img alt="European Commission logo"
                                    title="Home (European Commission)" className="ecl-site-header-core__logo-image"
                                    src="ec/logo--en.svg" />
                            </a>
                        </div>
                    </div>
                </header>
                <div className="ecl-page-header-core">
                    <div className="ecl-container">
                        <nav className="ecl-page-header-core__breadcrumb ecl-breadcrumb-core" aria-label="You are here:"
                            data-ecl-breadcrumb-core="true">
                            <ol className="ecl-breadcrumb-core__container">
                                <li className="ecl-breadcrumb-core__segment" data-ecl-breadcrumb-core-item="static" aria-hidden="false">
                                    <a href="http://ec.europa.eu/index_en.htm" className="ecl-breadcrumb-core__link ecl-link ecl-link--standalone">Home</a>
                                    <svg focusable="false" aria-hidden="true" role="presentation" className="ecl-breadcrumb-core__icon ecl-icon ecl-icon--2xs ecl-icon--rotate-90">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M20.623 19.5L12 9.83 3.377 19.5 1 16.835 12 4.5l11 12.335z"></path>
                                        </svg>
                                    </svg>
                                </li>
                                <li className="ecl-breadcrumb-core__segment" data-ecl-breadcrumb-core-item="expandable" aria-hidden="false">
                                    <a href="https://ec.europa.eu/info/live-work-travel-eu_en" className="ecl-breadcrumb-core__link ecl-link ecl-link--standalone">Live, work, travel in the EU</a>
                                    <svg focusable="false" aria-hidden="true" role="presentation" className="ecl-breadcrumb-core__icon ecl-icon ecl-icon--2xs ecl-icon--rotate-90">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M20.623 19.5L12 9.83 3.377 19.5 1 16.835 12 4.5l11 12.335z"></path>
                                        </svg>
                                    </svg>
                                </li>
                                <li className="ecl-breadcrumb-core__segment" data-ecl-breadcrumb-core-item="expandable" aria-hidden="true">
                                    <a href="https://ec.europa.eu/health/home_en" className="ecl-breadcrumb-core__link ecl-link ecl-link--standalone">Public Health</a>
                                    <svg focusable="false" aria-hidden="true" role="presentation" className="ecl-breadcrumb-core__icon ecl-icon ecl-icon--2xs ecl-icon--rotate-90">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M20.623 19.5L12 9.83 3.377 19.5 1 16.835 12 4.5l11 12.335z"></path>
                                        </svg>
                                    </svg>
                                </li>
                                <li className="ecl-breadcrumb-core__segment" data-ecl-breadcrumb-core-item="static" aria-hidden="false">
                                    <a href="https://ec.europa.eu/health/blood_tissues_organs/overview_en" className="ecl-breadcrumb-core__link ecl-link ecl-link--standalone">Blood, tissues, cells and organs</a>
                                    <svg focusable="false" aria-hidden="true" role="presentation" className="ecl-breadcrumb-core__icon ecl-icon ecl-icon--2xs ecl-icon--rotate-90">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M20.623 19.5L12 9.83 3.377 19.5 1 16.835 12 4.5l11 12.335z"></path>
                                        </svg>
                                    </svg>
                                </li>
                                <li className="ecl-breadcrumb-core__segment ecl-breadcrumb-core__current-page" aria-current="page" data-ecl-breadcrumb-core-item="static" aria-hidden="false">EU CCP Platform</li>
                            </ol>
                        </nav>
                        <h1 className="ecl-page-header-core__title">EU CCP Platform</h1>
                        <p className="ecl-page-header-core__description">COVID - 19 convalescent plasma collection and transfusion</p>
                    </div>
                </div>


                <div className="ecl-u-pv-xl ecl-u-pv-lg-3xl">
                    <div className="ecl-container">
                        <div className="ecl-row">
                            <main className="ecl-col-12 ecl-col-lg-9">
                                <p className="ecl-u-type-paragraph-lead">
The European Commission is working together with Member States, the <a target="_blank" className="ecl-link ecl-link--default" href="https://europeanbloodalliance.eu/" aria-label="Read more about European Blood Alliance (EBA)">European Blood Alliance (EBA)</a> and the <a target="_blank" className="ecl-link ecl-link--default" href="https://www.support-e.eu/" aria-label="Read more about the SUPPORT-E program">SUPPORT-E</a> project partners to provide a platform to support the study of convalescent plasma as a treatment for COVID-19 patients. The open-access database gathers and makes available data on convalescent plasma donations and patient outcomes following transfusions. It includes data from blood establishments regarding convalescent donors, plasma collection, and plasma components, as well as from clinical trials and from wider monitored use and will consolidate EU evidence on the safety and effectiveness of this therapy.
                                </p>
                                <div className="ecl-u-flex-grow-1 iframe-container">
                                    <iframe  className="responsive-iframe" title="DashboardSwitchBackup" src="https://app.powerbi.com/view?r=eyJrIjoiYjI5OGQxZGMtNmQ5Zi00ZDk3LWI2MDMtZDg4NzUzN2VlNmEwIiwidCI6ImIyNGM4YjA2LTUyMmMtNDZmZS05MDgwLTcwOTI2ZjhkZGRiMSIsImMiOjh9" frameborder="0" allowFullScreen="true"></iframe>
                                </div>
                            </main>
                            <aside className="ecl-col-12 ecl-col-lg-3">
                                {this._validAuthStates.includes(this.props.authState) && this.state.changePass === false && (
                                <form>
                                    <div className="ecl-form-group">
                                        <label className="ecl-form-label" htmlFor="username">Username<span className="ecl-form-label__required">*</span></label>
                                        <div className="ecl-help-block">Your email</div>
                                        {this.state.error ===  true &&(<div className="ecl-feedback-message">Incorrect Username and/or Password</div>)}
                                        <input type="text" id="username" key="username" name="username" className="ecl-text-input ecl-text-input--m" required=""
                                            value={this.state.username}
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <div className="ecl-form-group">
                                        <label className="ecl-form-label" htmlFor="password">Password<span className="ecl-form-label__required">*</span></label>
                                        <input type="password" id="password" key="password" name="password" className="ecl-text-input ecl-text-input--m" required=""
                                            value={this.state.password}
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <div className="ecl-form-group">
                                        <br />
                                        <button onClick={this.signIn} className="ecl-button ecl-button--primary">Sign In</button>
                                    </div>
                                </form>
                                )}
                                {this.state.changePass === true && (
                                <form>
                                    <div className="ecl-form-group">
                                        <label className="ecl-form-label" htmlFor="newpassword">New Password<span className="ecl-form-label__required">*</span></label>
                                        <input type="password" id="newpassword" key="newpassword" name="newpassword" className="ecl-text-input ecl-text-input--m" required=""
                                            value={this.state.newpassword}
                                            onChange={this.changeInput}
                                        />
                                    </div>
                                    <div className="ecl-form-group">
                                        <br />
                                        <button onClick={this.completeNewPassword} className="ecl-button ecl-button--primary">Save</button>
                                    </div>
                                </form>
                                )}
                            
                                <br />
                                <div>
                                    <h2 className="ecl-u-ma-none ecl-u-type-prolonged-xl ecl-u-type-bold">In partnership with</h2>
                                    <a target="_blank" href="https://europeanbloodalliance.eu/">
                                        <figure className="ecl-media-container">
                                            <img className="ecl-media-container__media"
                                                src="ec/EBA_logo.jpg" alt="European Blood Alliance (EBA)" />
                                        </figure>
                                    </a>
                                </div>

                                <br />
                                <div>
                                    <h2 className="ecl-u-ma-none ecl-u-type-prolonged-xl ecl-u-type-bold">More Info</h2>
                                    <ul className="ecl-u-mt-m ecl-unordered-list ecl-unordered-list--no-bullet">
                                        <li className="ecl-unordered-list__item">
                                            <a href="https://ec.europa.eu/health/blood_tissues_organs/covid-19_en" className="ecl-link--standalone ecl-link" target="_blank">
                                                Sante CCP homepage
                                            </a>
                                        </li>
                                        <li className="ecl-unordered-list__item">
                                            <a href="https://ec.europa.eu/health/sites/health/files/blood_tissues_organs/docs/guidance_plasma_covid19_en.pdf" className="ecl-link--standalone ecl-link" target="_blank">
                                                Guidance on collection, testing, processing, storage, distribution and monitored use
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>

                <footer className="ecl-footer-standardised">
                    <div className="ecl-container ecl-footer-standardised__container">
                        <section className="ecl-footer-standardised__section ecl-footer-standardised__section1">
                            <a href="/"
                                className="ecl-footer-standardised__title ecl-link ecl-link--standalone" aria-label="Link to site name">EU CCP Platform
                            </a>
                            <div className="ecl-footer-standardised__description">This site is managed by the Directorate-General for Health and Food Safety &quot;DG SANTE&quot;</div>
                        </section>
                        <div className="ecl-footer-standardised__section2">
                            <section className="ecl-footer-standardised__section">
                                <div className="ecl-footer-standardised__title ecl-footer-standardised__title--separator">Contact us</div>
                                <ul className="ecl-footer-standardised__list">
                                    <li className="ecl-footer-standardised__list-item">
                                        <a href="https://ec.europa.eu/info/departments/health-and-food-safety_en#contact"
                                            className="ecl-footer-standardised__link ecl-link ecl-link--standalone"
                                            aria-label="Link to Contact information of the DG">Contact information of the DG</a>
                                    </li>
                                </ul>
                            </section>
                            <section className="ecl-footer-standardised__section">
                                <div className="ecl-footer-standardised__title ecl-footer-standardised__title--separator">Follow us on</div>
                                <ul className="ecl-footer-standardised__list ecl-footer-standardised__list--inline">
                                    {/*
                                    <li className="ecl-footer-standardised__list-item">
                                        <a href="/example"
                                            className="ecl-footer-standardised__link ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before"
                                            aria-label="Link to Facebook">
                                            <svg focusable="false" aria-hidden="true" className="ecl-link__icon ecl-icon ecl-icon--xs">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="branded--facebook"><path d="M24 12.073C24 5.405 18.627 0 12 0S0 5.405 0 12.073C0 18.099 4.388 23.094 10.125 24v-8.437H7.078v-3.49h3.047v-2.66c0-3.025 1.792-4.697 4.533-4.697 1.312 0 2.686.236 2.686.236v2.971h-1.513c-1.491 0-1.956.931-1.956 1.886v2.264h3.328l-.532 3.49h-2.796V24C19.612 23.094 24 18.099 24 12.073"></path></svg>
                                            </svg>
                                            &nbsp;<span className="ecl-link__label">Facebook</span>
                                        </a>
                                    </li>
                                    */}
                                    <li className="ecl-footer-standardised__list-item">
                                        <a href="https://twitter.com/eu_health" className="ecl-footer-standardised__link ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before"
                                            aria-label="Link to Twitter">
                                            <svg focusable="false" aria-hidden="true" className="ecl-link__icon ecl-icon ecl-icon--xs">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="branded--twitter"><path d="M20.592 8.031C20.592 14.068 16.104 21 7.896 21 5.269 21 2.86 20.217 1 18.876h.985c2.08 0 3.94-.783 5.582-2.013a4.415 4.415 0 01-4.268-3.13c.437.112.656.112.875.112.438 0 .766 0 1.204-.112-2.08-.447-3.612-2.236-3.612-4.472.657.223 1.314.559 2.08.559-1.314-.783-2.08-2.236-2.08-3.801 0-.895.22-1.677.657-2.348 2.189 2.907 5.582 4.695 9.303 4.92-.11-.448-.11-.672-.11-1.007 0-2.46 2.08-4.584 4.488-4.584 1.423 0 2.627.559 3.394 1.453.985-.111 1.97-.559 2.845-1.006-.219 1.006-.985 2.013-1.97 2.46.876-.112 1.751-.447 2.627-.783-.766.783-1.532 1.565-2.408 2.236v.671z"></path></svg>
                                            </svg>
                                            &nbsp;<span className="ecl-link__label">Twitter</span>
                                        </a>
                                    </li>
                                    {/*
                                    <li className="ecl-footer-standardised__list-item">
                                        <a href="/example"
                                            className="ecl-footer-standardised__link ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-before"
                                            aria-label="Link to Linkedin">
                                            <svg focusable="false" aria-hidden="true" className="ecl-link__icon ecl-icon ecl-icon--xs">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="branded--linkedin"><path d="M5.84 22.5H1.55V8.077h4.29V22.5zM3.64 6.346C2.1 6.346 1 5.308 1 3.923S1.99 1.5 3.64 1.5c1.54 0 2.64 1.038 2.64 2.423s-.99 2.423-2.64 2.423zM23 22.5h-4.95V15c0-1.846-.77-3.23-2.42-3.23-1.32 0-1.98.922-2.31 1.73v9H8.48V8.077h4.84v2.308c.22-.923 1.76-2.423 4.29-2.423 3.08 0 5.39 2.076 5.39 6.461V22.5z"></path></svg>
                                            </svg>
                                            &nbsp;<span className="ecl-link__label">Linkedin</span>
                                        </a>
                                    </li>
                                    */}
                                </ul>
                            </section>
                        </div>
                        <div className="ecl-footer-standardised__section3">
                            <section className="ecl-footer-standardised__section">
                                <div className="ecl-footer-standardised__title ecl-footer-standardised__title--separator">About us</div>
                                <ul className="ecl-footer-standardised__list">
                                    <li className="ecl-footer-standardised__list-item">
                                        <a href="https://ec.europa.eu/info/departments/health-and-food-safety_en"
                                            className="ecl-footer-standardised__link ecl-link ecl-link--standalone"
                                            aria-label="Link to Information about the DG">Information about the DG</a>
                                    </li>
                                </ul>
                            </section>
                            {/*
                            <section className="ecl-footer-standardised__section">
                                <div className="ecl-footer-standardised__title ecl-footer-standardised__title--separator">Related sites</div>
                                <ul className="ecl-footer-standardised__list">
                                    <li className="ecl-footer-standardised__list-item">
                                        <a href="/example" className="ecl-footer-standardised__link ecl-link ecl-link--standalone"
                                            aria-label="Link to Related link 1">Related link 1</a>
                                    </li>
                                </ul>
                            </section>
                            */}
                        </div>
                        <section className="ecl-footer-standardised__section ecl-footer-standardised__section7">
                            <a href="https://ec.europa.eu/info/index_en" className="ecl-footer-standardised__title ecl-link ecl-link--standalone"
                                aria-label="Link to European Commission">European Commission</a>
                        </section>
                        <section className="ecl-footer-standardised__section ecl-footer-standardised__section8">
                            <ul className="ecl-footer-standardised__list">
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://ec.europa.eu/info/about-european-commission/contact_en"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone"
                                        aria-label="Link to Contact the European Commission">Contact the European Commission</a>
                                </li>
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://europa.eu/european-union/contact/social-networks_en#n:+i:4+e:1+t:+s"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-after"
                                        aria-label="Link to Follow the European Commission on social media">
                                            <span className="ecl-link__label">Follow the European Commission on social media</span>
                                            &nbsp;<svg focusable="false" aria-hidden="true" className="ecl-link__icon ecl-icon ecl-icon--xs">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ui--external"><path d="M1 1l8.25.046v2.71H3.796V20.29H20.25v-5.465H23V23H1V1zm11.468 0H23v10.494l-3.944-3.93-5.892 5.87-2.599-2.588 5.847-5.824L12.468 1z"></path></svg>
                                            </svg>
                                    </a>
                                </li>
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://ec.europa.eu/info/resources-partners_en"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone">Resources for partners</a>
                                </li>
                            </ul>
                        </section>
                        <section className="ecl-footer-standardised__section ecl-footer-standardised__section9">
                            <ul className="ecl-footer-standardised__list">
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://ec.europa.eu/info/language-policy_en"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone"
                                        aria-label="Link to Language policy">Language policy</a>
                                </li>
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://ec.europa.eu/info/cookies_en"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone" aria-label="Link to Cookies">Cookies</a>
                                </li>
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://ec.europa.eu/info/privacy-policy_en"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone"
                                        aria-label="Link to Privacy policy">Privacy policy</a>
                                </li>
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://ec.europa.eu/info/legal-notice_en"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone" aria-label="Link to Legal notice">Legal notice</a>
                                </li>
                                <li className="ecl-footer-standardised__list-item">
                                    <a href="https://ec.europa.eu/info/brexit-content-disclaimer_en"
                                        className="ecl-footer-standardised__link ecl-link ecl-link--standalone"
                                        aria-label="Link to Brexit content disclaimer">Brexit content disclaimer</a>
                                </li>
                            </ul>
                        </section>
                    </div>
                </footer>

            </>
        )
    
    }
}