import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import CustomButton from '../Base/CustomButton/CustomButton'
import { postData } from '../../utils/api'
import PATH from '../../constants/api'
import { 
    ExpansionPanel, 
    ExpansionPanelSummary, 
    ExpansionPanelDetails, 
    Typography, 
    Grid,
    Card,
    CircularProgress
} from '@material-ui/core';
import { Auth, Storage } from 'aws-amplify';
import { ExpandMore, Cached } from '@material-ui/icons';
import './CustomUploadHistory.css'


class CustomUploadHistory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploadHistory: [],
            uploading: false,
            file: undefined,
            dragText: `Drag and drop the excel file here, or click to select the file`,
            showProgress: true,
            refresh: false
        }

        this.uploadFile = this.uploadFile.bind(this)
        this.dopFile = this.dopFile.bind(this)
        this.getHistory = this.getHistory.bind(this)
        this.handleDragEnter = this.handleDragEnter.bind(this)
        this.refreshHistory = this.refreshHistory.bind(this)
    }

    componentDidMount = async () => {
        try {
            let resultArray = await this.getHistory()
            this.setState({
                uploadHistory: resultArray
            })
        } catch(error) {
            console.log(error)
        }
    }

    getHistory = async () => {
        try {
            this.setState({
                showProgress: true
            })
            let resultArray = []
            const data = await postData(PATH.test.upload_history, {})
            if (data.statusCode === 200) {
                data.body.entries.forEach(item => {
                    let tempString = item[Object.keys(item)[0]].slice(2, item[Object.keys(item)[0]].length - 3)
                    tempString = tempString.split("\\n")
                    if(!Object.keys(item)[0].includes("FAILURES")){
                        resultArray = [...resultArray, {
                            date: Object.keys(item)[0],
                            err: undefined,
                            data: tempString
                        }]
                    } else {
                        const splitFailure = Object.keys(item)[0].split(" ")
                        resultArray = [...resultArray, {
                            date: splitFailure[0],
                            err: splitFailure[1],
                            data: tempString
                        }]
                    }
                })
            }
            this.setState({
                showProgress: false
            })
            return resultArray
        } catch(e) {
            console.log(e)
            this.setState({
                showProgress: false
            })
            throw e
        }
    }

    downloadFile = async () => {
        var result = await Storage.get('Validated_donations.xlsm')
        var a = document.createElement("a");
        a.href = result;
        var fileName = result.split("/").pop();
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(result);
        a.remove();
    }

    uploadFile = async () => {
        try {
            this.setState({
                uploading: true
            })
            if (this.state.file === undefined) {
                this.props.showAlert('warning', "Please select a file!")
                throw new TypeError('No file selected')
            }
            const session = await Auth.currentSession();
            var today = new Date();
            var date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date + "-" + time;
            var filename = "donations/" + dateTime;
            //currently filename in s3 will be datetime of upload to avoid conflicts. Consider addng identifiable info to detect and fix errors in upload for specific BE
            await Storage.put(filename, this.state.file, {
                level: 'private',
                contentType: this.state.file.type,
                metadata: { IdToken: session.getIdToken().getJwtToken()}
            })
            this.props.showAlert('success', "Upload successful!")
            let resultArray = await this.getHistory()
            this.setState({
                dragText: `Drag and drop the excel file here, or click to select the file`,
                uploading: false,
                uploadHistory: resultArray
            })
        } catch(e) {
            this.props.showAlert('error', "Upload failed!")
            console.log(e)
            this.setState({
                uploading: false
            })
        }
    }

    dopFile = (file) => {
        var regex = /(\.xls|\.xlsx|\.XLS|\.XLSX|\.xlsm|\.XLSM)$/;
        if (regex.test(file[0].name.toLowerCase())) {
            this.setState({
                file: file[0],
                dragText: file[0].name
            })
        } else {
            console.log("Not Excel")
            this.props.showAlert('error', 'Upload an Excel File!')
        }
    }

    refreshHistory = async () => {
        try {
            this.setState({
                refresh: true,
                uploadHistory: []
            })
            let resultArray = await this.getHistory()
            this.setState({
                uploadHistory: resultArray,
                refresh: false
            })
        } catch(error) {
            this.setState({
                refresh: false
            })
            console.log(error)
        }
    }

    handleDragEnter = (e, enter=false) => {
        e.preventDefault()
        let text = `Drop the excel file here`
        if(!enter) {
            text = `Drag and drop the excel file here, or click to select the file`
        }
        this.setState({
            dragText: text,
        })
    }

    render() {
        return (
            <>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container justify="center"> 
                            <Grid item xs={12} md={6} lg={4}>
                                <Card>
                                    <Dropzone 
                                        onDrop={acceptedFiles => this.dopFile(acceptedFiles)} 
                                        onDragEnter={event => this.handleDragEnter(event, true)}
                                        onDragLeave={event => this.handleDragEnter(event, false)}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <Grid container justify="center" alignItems="center" className="drop-zone-box">
                                                <div {...getRootProps()} className="drop-zone-input">
                                                    <input {...getInputProps()} />
                                                    <p className="drop-zone-text">{this.state.dragText}</p>
                                                </div>
                                            </Grid>
                                        )}
                                    </Dropzone>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={2} lg={2} className="container-button">
                                <Grid container justify={'center'} alignItems="center" >
                                    {this.state.uploading ?
                                        <CircularProgress 
                                            size={30}
                                        />
                                    :
                                        <CustomButton 
                                            disabled={this.state.uploading}
                                            variant="contained"
                                            color="secondary"
                                            text="Upload"
                                            onClick={this.uploadFile}
                                        />
                                    }
                                <CustomButton
                                            variant="contained"
                                            color="secondary"
                                            text="Download template"
                                            onClick={this.downloadFile}
                                />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="center" className="container-history-text"> 
                            <Typography variant="h4">
                                Upload History
                            </Typography>
                            <Typography variant="h4" className="refresh-button">
                                <Cached onClick={this.refreshHistory} className={this.state.refresh ? 'refresh-animation' : ''}/>
                            </Typography>
                        </Grid>
                        <Grid container justify="center"> 
                            <Grid item xs={12} md={8} lg={6}>
                                {this.state.uploadHistory.map((item, index) => (
                                    <ExpansionPanel key={`expans_${index}`}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls={`panel1a-content`}
                                            id={index}
                                        >
                                            <Grid container justify="center"> 
                                                <Typography component={'span'}>{item.date}</Typography>
                                                {item.err !== undefined ? 
                                                    <Typography color={'error'} component={'span'} className="error-space">{item.err}</Typography>
                                                : 
                                                    null
                                                }
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={3}>
                                                {item.data.map((elem, index) => (
                                                    
                                                    <Grid item xs={12} key={index}>
                                                        <Typography variant="body1">
                                                            {elem}
                                                        </Typography>
                                                    </Grid>
                                                    
                                                ))}
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>  
                                ))}
                                {this.state.uploadHistory.length === 0 && !this.state.showProgress ? 
                                    <Card>
                                        <Grid container justify="center" alignItems="center" className="no-uploads-box">
                                            <Typography variant="body1">
                                                No recent uploads
                                            </Typography>
                                        </Grid>
                                    </Card>
                                    : null
                                }
                                {this.state.showProgress ?
                                    <Grid container justify="center" alignItems="center">
                                        <CircularProgress />
                                    </Grid>
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

CustomUploadHistory.propTypes = {
};

export default CustomUploadHistory;