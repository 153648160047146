import FORM_FIELDS from '../constants/formInputs'
import FORM_FIELDS2 from '../constants/formInputs2'
import FORM_FIELDS3 from '../constants/formInputs3'

const getQuestions = (name) => {
    let questions
    switch (name) {
        case 'clinicaluse':
            questions = sortQuestions(FORM_FIELDS3)
            break
        case 'trial':
            questions = sortQuestions(FORM_FIELDS2)
            break
        case 'donation':
            questions = sortQuestions(FORM_FIELDS)
            break
        default:
            questions = sortQuestions(FORM_FIELDS)
            break
    }
    return questions
}

const sortQuestions = (questions) => {
    const sortedQuestions = questions.sort((a, b) => a.order - b.order)

    return sortedQuestions
}

const getInputValues = (data, targetId, targetName, value, multy) => {
    let tempState = data
    let findField = tempState.findIndex(item => item.id === targetId || item.id === targetName)
    if (findField >= 0) {
        tempState[findField].value = value
    }
    let findDependency = tempState.filter(item => {
        
        if(item.transfusionDepends!==undefined){
            const findInTrasfusionDep = item.transfusionDepends.find(obj => obj.id === targetId || obj.id === targetName)
            if(findInTrasfusionDep!== undefined)
            {
                let findAnswer = undefined;
                findAnswer = findInTrasfusionDep.answers.find(obj => obj === value)

                if(findAnswer!==undefined)
                    return true
                if(item.value !== undefined)
                    {
                        if(item.type==="multiselect"){
                            item.choices.map((elem,index) => {
                                elem.value = false;
                            })
                        }
                        item.value = ''
                    }
 
                item.visible = false

            }
        }

        if (item.depends !== undefined) {
            const findInDep = item.depends.find(obj => obj.id === targetId || obj.id === targetName)
            
            if (findInDep !== undefined ) {
                let findAnswer = undefined;
                if(!item.dependenciesType){
                    if(!multy )
                        findAnswer = findInDep.answers.find(obj => obj === value)
                    else 
                        findAnswer = findInDep.answers.find(obj => value.find(it => it.label === obj))
                }
                else{
                    if(!multy)
                        findAnswer = item.depends.find(obj => (obj.id === targetId || obj.id === targetName) && obj.answers[0] === value)
                    else 
                        findAnswer = item.depends.find(obj => (obj.id === targetId || obj.id === targetName) && value.find(it => it.label === obj.answers[0]))
                }
                if (findAnswer !== undefined)
                    return true
                if(item.value !== undefined)
                    item.value = ''    
                item.visible = false
                return false
            }

        

            return false        
        }
        return false 
    })
    findDependency.forEach(elem => {
        const findElem = tempState.find(item => item.id === elem.id)
        if (findElem !== undefined) {

            let transfusionDepends = true;
            if(findElem.transfusionDepends){

                let visibleArr = []
                findElem.transfusionDepends.forEach(obj => {
                    let findDep = data.find(elem => elem.id === obj.id)


                    if(findDep !== undefined) { 
                        if(multy && findDep.type === "multiselect"){
                            if(findDep.value.find(it => it.label === obj.answers))
                                visibleArr = [...visibleArr, true]                                                    
                        }
                        else{
                            if(obj.answers.indexOf(findDep.value) >= 0 ){
                                visibleArr = [...visibleArr, true]
                            } else {
                                visibleArr = [...visibleArr, false]
                            }
                        }
                    }
                })
                transfusionDepends = (visibleArr.indexOf(false) >= 0 ? false : true)

                //console.log(elem,visibleArr,transfusionDepends)

            }

            if(!findElem.dependenciesType){

                let visibleArr = []
                if(findElem.depends!==undefined) 
                {
                    findElem.depends.forEach(obj => {
                        let findDep = data.find(elem => elem.id === obj.id)


                        if(findDep !== undefined) { 
                            if(multy && findDep.type === "multiselect"){
                                if(findDep.value.find(it => it.label === obj.answers))
                                    visibleArr = [...visibleArr, true]                                                    
                            }
                            else{
                                if(obj.answers.indexOf(findDep.value) >= 0 ){
                                    visibleArr = [...visibleArr, true]
                                } else {
                                    visibleArr = [...visibleArr, false]
                                }
                            }
                        }
                    })
                }
                findElem.visible = (visibleArr.indexOf(false) >= 0 ? false : true && transfusionDepends? true : false);
            }
            else{

                let findElement = undefined;

                if (!multy)
                    findElement = findElem.depends.find(obj => obj.answers[0] === value)
                else
                    findElement = findElem.depends.find(obj => value.find(it => it.label === obj.answers[0]))

                if (findElement!==undefined && transfusionDepends)
                    findElem.visible = true;
                else 
                    findElem.visible = false

                //console.log("else", findElem.visible, transfusionDepends)

            }
        }
    })

    return tempState
}

export {
    getQuestions,
    getInputValues
}