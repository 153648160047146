import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Drawer, List, ListItem, Divider, ListItemIcon, ListItemText, Typography, Grid} from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import './CustomDrawer.css'

export default class CustomDrawer extends Component {

    render() {

        return (
            <Drawer anchor={'left'} open={this.props.open} onClose={event => this.props.onClick(event, false)}>
                <Grid container justify="center">
                    <Typography variant="h4" className="title-menu">
                        EUCCP
                    </Typography>
                </Grid>
                <Divider />
                <List>
                    <ListItem button onClick={event => this.props.onMenuClick(event, "donation")}>
                        <ListItemIcon>
                            <Assignment />
                        </ListItemIcon>
                        <ListItemText primary={'Donation Module'} />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={event => this.props.onMenuClick(event, "trial")}>
                        <ListItemIcon>
                            <Assignment />    
                        </ListItemIcon>
                        <ListItemText primary={'Patient Module'} />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={event => this.props.onMenuClick(event, "clinicaluse")}>
                        <ListItemIcon>
                            <Assignment />    
                        </ListItemIcon>
                        <ListItemText primary={'Clinical Use Module'} />
                    </ListItem>
                </List>
                <Divider />
                {<List>
                    <ListItem button onClick={event => this.props.onMenuClick(event, "analytics")}>
                        <ListItemIcon>
                            <Assignment />    
                        </ListItemIcon>
                        <ListItemText primary={'Analytics Module'} />
                    </ListItem>
                </List>}
            </Drawer>
        )
    }
}

CustomDrawer.propTypes = {
    onClick: PropTypes.func,
    open: PropTypes.bool,
    onMenuClick: PropTypes.func,
};