import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default class CustomTabPanel extends Component {
    render() {

        return (
            <div
                role="tabpanel"
                hidden={!this.props.isVisible}
            >
                <Box p={3} style={{paddingTop: '180px'}}>
                    <Typography component={'span'}>{this.props.children}</Typography>
                </Box>
            </div>
        )
    }
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool
};