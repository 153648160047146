import React, { Component } from 'react';
import { getQuestions, getInputValues } from '../../utils/FormUtils'
import CustomInput from '../Base/CustomInput/CustomInput'
import CustomSelect from '../Base/CustomSelect/CustomSelect'
import CustomButton from '../Base/CustomButton/CustomButton'
import { Box, CircularProgress, Card , Grid } from '@material-ui/core'
import PropTypes from 'prop-types';
import { postData } from '../../utils/api'
import PATH from '../../constants/api'
import './CustomForm.css'
import $ from 'jquery';

class CustomForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            questions: [],
            disabled: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount = () => {
        let questions = JSON.parse(JSON.stringify(getQuestions('donation')))

        questions.forEach(element => {
            if(!this.props.new) {
                if(element.id === 'donation_id') {
                    element['disabled'] = true
                }
                element['value'] = this.props.data[element.id]
                element['key'] = this.props.data.donation_skey
                //calling getInputValues for each to evaluate visibility of questions when data is preloaded in review donations
                //without this, dependent question will not become visible unless you deselect and reselect the dependent answer
                getInputValues(questions, element.id, element.id, element['value'], false)
            } else {
                element['value'] = ''
            }
        });

        this.setState({
            questions: questions,
        })

        $(document).on("wheel", "input[type=number]", function (e) {
            $(this).blur();
        });
    }

    handleChange = (event) => {
        if(event.target.type === 'number' && event.target.value < 0) {
            event.target.value = 0
        }
        let tempState = this.state.questions
        let targetId = ''
        let targetName = ''
        if(!this.props.new){
            if(event.target.id !== undefined) {
                const splitId = event.target.id.split('_SPLIT_')
                targetId = splitId[0]
            }
            const splitName = event.target.name.split('_SPLIT_')
            targetName = splitName[0]
        } else {
            targetId = event.target.id
            targetName = event.target.name
        }
        
        tempState = getInputValues(tempState, targetId, targetName, event.target.value, false)

        this.setState({
            questions: tempState
        })

    }

    onSubmit = async e => {
        try {
            this.setState({
                disabled: true
            })
            e.preventDefault();

            const payload = {}

            this.state.questions.forEach(item => {
                if(item.required && item.value.replace(/\s/g,"") === ""){
                    this.props.showAlert('warning', `Field '${item.label}' is required`)
                    throw new Error('Empty Field')
                }
                // 86400 seconds in a day, Date object is in milliseconds. workaround to allow donation logged for today
                if(item.type === 'date' && (Date.parse(item.value) > Date.now() + 86400000  || Date.parse(item.value) < Date.parse('01 Jan 2020 00:00:00 GMT'))) {
                    this.props.showAlert('warning', `Please provide a date after Jan 1st 2020 and before today for the field: ${item.label}`)
                    throw new Error('Future date')
                }
                if (item.type === 'number') {
                    var myRe = /^[0-9]*$/
                    if (!myRe.test(item.value)){
                        this.props.showAlert('warning', `Invalid symbols in number field: ${item.id}`)
                        throw new Error('Bad characters in number field')
                        }
                    else if (item.id === 'donation_plasma_volume' && item.value !== "" && (item.value < 0 || item.value > 1500)) {
                        this.props.showAlert('warning', `Minimum value = 0, maximum value = 1500 for field: ${item.label}`)
                        throw new Error('Bad plasma volume')
                    }
                    else if (item.id === 'donation_plasma_unit_mean_volume' && item.value !== "" && (item.value < 100 || item.value > 500)) {
                        this.props.showAlert('warning', `Minimum value = 100, maximum value = 500 for field: ${item.label}`)
                        throw new Error('Bad plasma unit volume')
                    }
                }
                payload[item.id] = item.value
            }) 
            payload['new'] = this.props.new
            //adding a field to the payload to do error management for new donation module

            let newQuestions = getQuestions('donation')
            newQuestions.forEach(element => {
                element['value'] = ''
            });
            //Pass failure alerts from lambda
            console.log("about to post to api path -",PATH.test.path )
            console.log("payload -",payload)            
            const result = await postData(PATH.test.path, payload)
            console.log(result)
            if (result.statusCode === 200) {
                this.props.showAlert('success', result.body)
                if (this.props.new) {
                    this.setState({
                        questions: newQuestions,
                })}
            }
            else {
                this.props.showAlert('error', result.errorMessage)
                newQuestions=this.state.questions
            }
            this.setState({
                disabled: false,
            })

        } catch(e) {
            //pass better errors from lambda and pass them to front in alert
            console.log(e)
            this.setState({
                disabled: false
            })
        }
    };

    render() {
        return (
            <>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={(!this.props.new ? 12 : 12)} md={(!this.props.new ? 12 : 8)} lg={(!this.props.new ? 12 : 6)}>
                        <Card>
                            {this.state.questions.map((elem, index) => (
                                elem.visible ? (elem.type === 'select' ? 
                                <Box 
                                    justifyContent="center" flexGrow={1}
                                    key={index}
                                    className="container-input-form"
                                >
                                    <CustomSelect 
                                        id={!this.props.new ? `${elem.id}_SPLIT_${this.props.data.donation_skey}` : elem.id} 
                                        required={elem.required}
                                        label={elem.label}
                                        name={!this.props.new ? `${elem.name}_SPLIT_${this.props.data.donation_skey}` : elem.name} 
                                        value={elem.value}
                                        choices={elem.choices}
                                        onChange={this.handleChange}
                                        helperText={elem.helperText}
                                        inputProps={elem.inputProps}
                                        InputLabelProps={elem.InputLabelProps}
                                    />
                                </Box>
                                : 
                                <Box 
                                    justifyContent="center" flexGrow={1}
                                    key={index}
                                    className="container-input-form"
                                >
                                    <CustomInput 
                                        id={!this.props.new ? `${elem.id}_SPLIT_${this.props.data.donation_skey}` : elem.id} 
                                        required={elem.required}
                                        disabled={elem.disabled !== undefined ? (elem.disabled || this.state.disabled) : this.state.disabled }
                                        type={elem.type}
                                        label={elem.label}
                                        name={!this.props.new ? `${elem.name}_SPLIT_${this.props.data.donation_skey}` : elem.name} 
                                        value={elem.value}
                                        helperText={elem.helperText}
                                        onChange={this.handleChange}
                                        inputProps={elem.inputProps}
                                        pattern={elem.pattern}
                                        InputLabelProps={elem.InputLabelProps}
                                    />
                                </Box>) 
                                : null
                            ))}
                            <Box className="padding-bottom-card"></Box>
                        </Card >
                    </Grid>
                </Grid>
                <Grid container spacing={2} justify="center" className="container-button">
                    <Grid item xs={(!this.props.new ? 12 : 6)}>
                        <Grid container justify="center">
                            {this.state.disabled ? 
                                <CircularProgress 
                                    size={30}
                                />
                            :
                                <CustomButton 
                                    variant="contained"
                                    color="secondary"
                                    text= {!this.props.new ? "Save" : "Submit"}
                                    disabled={this.state.disabled}
                                    onClick={this.onSubmit}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

CustomForm.propTypes = {
    new: PropTypes.bool,
    data: PropTypes.object
};

export default CustomForm;