import React, { Component } from 'react';
import { 
    ExpansionPanel, 
    ExpansionPanelSummary, 
    ExpansionPanelDetails, 
    Typography, 
    CircularProgress,
    Box,
    Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { postData } from '../../utils/api'
import PATH from '../../constants/api'
import CustomTrialForm from '../CustomTrialForm/CustomTrialForm'
import CustomInput from '../Base/CustomInput/CustomInput'
import CustomButton from '../Base/CustomButton/CustomButton'


class CustomVisualize extends Component {
    constructor(props) {
        super(props)


        this.linkKibana = this.linkKibana.bind(this)
    }

    componentDidMount = async () => {
    /*try  {
            const data = await fetchData(PATH.test.path)
            const paged = this.addItem(data.body.entries)
            this.setState(prevState => {
                return {
                    donations: data.body.entries,
                    visibleDonations: prevState.visibleDonations.concat(paged),
                    pageIndex: prevState.pageIndex + PAGE_SIZE
                }
            })
        } catch(error) {
            console.log(error)
        }*/
    }
/*
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.extractDonation()
        }
    }
*/
    linkKibana = async () => {
        var a = document.createElement("a");
        a.href = "https://search-euccp-es-cluster-pdqnfure4lqx5x4lew6jmtnxjq.eu-west-1.es.amazonaws.com/_plugin/kibana"
        a.click()
        a.remove();

//<li>
  //                                      <a href="https://ec.europa.eu/info/brexit-content-disclaimer_en"
    //                                        aria-label="Link to Brexit content disclaimer">Kibana</a>
      //                              </li>
    }

    render() {
        return (
            <>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Grid container justify="center" alignItems="flex-end">
                            <Grid item xs={3} sm={2} lg={1}>
                                <Grid container justify="flex-end">
                                    <CustomButton 
                                        variant="contained"
                                        color="secondary"
                                        text="Kibana sign-in"
                                        onClick={this.linkKibana}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
            </>
        )
    }
}

export default CustomVisualize