const DONATION_TAB = [
    "New donation",
    "Upload Excel",
    "Review donations"
]
const TRIAL_TAB = [
    "New patient",
    "Upload Excel",
    "Review patients"
]
const CLINICAL_USE_TAB = [
    "New clinical use",
    "Review clinical use"
]
const ANALYTICS_TAB = [
    "Extract my data",
    "Visualize (Kibana)"
]
export {
    DONATION_TAB,
    TRIAL_TAB,
    CLINICAL_USE_TAB,
    ANALYTICS_TAB
}