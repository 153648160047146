import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';

export default class CustomButton extends Component {
    render() {

        return (
            <Button 
                variant={this.props.variant}
                color={this.props.color}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            >
                {this.props.text}
            </Button>
        )
    }
}

CustomButton.propTypes = {
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
};