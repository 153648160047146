const FORM_FIELDS2 = [
    {
        id: 'patient_id',
        order:1,
        required: true,
        type: 'text',
        label: 'What is the patient id?',
        name: 'patient_id',
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",      
        visible: true,
        depends: undefined,
        choices: undefined,
    },
    {
        id: 'patient_treatment_setting',
        order: 2,
        required: false,
        type: 'select',
        label: 'Was the patient treated in a registered clinical trial or in a monitored use programme?',
        name: 'patient_treatment_setting',
        visible:true,
        depends:undefined,
        choices: [
            {
                value: 'a registered clinical trial',
                label: 'a registered clinical trial'
            },
            {
                value: 'a monitored use programme',
                label: 'a monitored use programme'
            }
        ]
    },
    {
        id:'patient_clinical_trial_id',
        order: 3,
        required: false,
        type: 'text',
        label:'What is the clinical study identifier (EudraCT N°, ClinicalTrials.gov identifier or other)?',
        name:'patient_clinical_trial_id',
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",      
        visible: false,
        depends: [
            {
                id: 'patient_treatment_setting',
                answers: [
                    'a registered clinical trial'
                ]
            }
        ],
        choices: undefined,
    },
    {
        id:'patient_gender',
        order: 4,
        required: false,
        type: 'select',
        label: 'What is the gender of the patient?',
        name: 'patient_gender',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'Male',
                label: 'Male'
            },
            {
                value: 'Female',
                label: 'Female'
            }
        ]
    },
    {
        id: 'patient_age',
        order: 5,
        required: false,
        type: 'select',
        label: "What was the age of the patient at inclusion?",
        name: "patient_age",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "0-10 years old",
                label: '0-10 years old'
            },
            {
                value: "11-20 years old",
                label: '11-20 years old'
            },
            {
                value: "21-30 years old",
                label: '21-30 years old'
            },
            {
                value: "31-40 years old",
                label: '31-40 years old'
            },
            {
                value: "41-50 years old",
                label: '41-50 years old'
            },
            {
                value: "51-60 years old",
                label: '51-60 years old'
            },
            {
                value: "61-70 years old",
                label: '61-70 years old'
            },
            {
                value: "71-80 years old",
                label: '71-80 years old'
            },
            {
                value: "81-90 years old",
                label: '81-90 years old'
            },
            {
                value: "91-100 years old",
                label: '91-100 years old'
            }
        ]
    },
    {
        id: 'patient_blood_group',
        order: '6',
        required: false,
        type: 'select',
        label: 'What is the blood group of the patient?',
        name: 'patient_blood_group',
        visible: true,
        depends: undefined,
        choices:[
            {
                value: 'O',
                label: 'O'
            },
            {
                value: 'A',
                label: 'A'
            },
            {
                value: 'B',
                label: 'B'
            },
            {
                value: 'AB',
                label: 'AB'
            }
        ]
    },
    {
        id: 'patient_has_comorbidities',
        order: 7,
        required: false,
        type: 'select',
        label: 'Is the patient presenting comorbidities?',
        name: 'patient_has_comorbidities',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'no'
            }
        ]
    },
    {
        id: 'patient_comorbidities',
        order: 8,
        required: false,
        type: 'multiselect',
        label: 'Which comorbidities does the patient present?',
        name: 'patient_comorbidities',
        visible: false,
        depends:[
            {
                id: 'patient_has_comorbidities',
                answers:[
                    'True'
                ]
            }
        ],
        choices: [
            {
                value: false,
                label: 'Obesity'
            },
            {
                value: false,
                label: 'Diabetes'
            },
            {
                value: false,
                label: 'Hypertension'
            },
            {
                value: false,
                label: 'Cardiovascular disease'
            },
            {
                value: false,
                label: 'Chronic Respiratory disease'
            },
            {
                value: false,
                label: 'Chronic Kidney disease'
            },
            {
                value: false,
                label: 'Blood cancer'
            },
            {
                value: false,
                label: 'Cancer'
            },
            {
                value: false,
                label: 'Autoimmunity'
            },
            {
                value: false,
                label: 'HIV'
            },
            {
                value: false,
                label: 'Pregnancy'
            },
            {
                value: false,
                label: 'Other'
            }
        ]
    },
    {
        id: 'patient_comorbidities_if_other',
        order: 9,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: 'max 255 characters',
        label: 'Describe any other comorbidities:',
        name: 'patient_comorbidities_if_other',
        visible: false,
        depends: [
            {
                id:'patient_comorbidities',
                answers: [
                    'Other'
                ]
            },
            {
                id: 'patient_has_comorbidities',
                answers:[
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_covid19_diagnosis_method',
        order: 10,
        required: false,
        type: 'select',
        label: 'How was the COVID-19 diagnosis made?',
        name: 'patient_covid19_diagnosis_method',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'lung CT scan',
                label: 'lung CT scan'
            },
            {
                value: 'RT-PCR',
                label: 'RT-PCR'
            },
            {
                value: 'both',
                label: 'both'
            },
            {
                value: 'information not yet available',
                label: 'information not yet available'
            },
            {
                value: 'other',
                label:'other'
            }
        ]
    },
    {
        id: 'patient_covid19_diagnosis_method_if_other',
        order: 11,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: 'max 255 characters',
        label: 'Describe how the COVID-19 diagnosis was made?',
        name:'patient_covid19_diagnosis_method_if_other',
        visible: false,
        depends:[
            {
                id: 'patient_covid19_diagnosis_method',
                answers: [
                    'other'
                ]
            }
        ],
        choices: undefined
    },

    // First Transfusion Episode (T1)

    {
        id: '1_transfusion_episode_onset_symptoms_interval',
        order: 12,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the number of days between the onset of symptoms and first transfusion?',
        name: '1_transfusion_episode_onset_symptoms_interval',
        visible: true,
        depends: undefined,
        choices: undefined
    },
    {
        id: '1_transfusion_episode_symptoms_severity',
        order: 13,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 8',
        label: 'What was the severity at the first transfusion episode, following the COVID-19 WHO scale?',
        name: '1_transfusion_episode_symptoms_severity',
        visible: true,
        depends: undefined,
        choices: undefined
        //PICTURE
    },
    {
        id: '1_transfusion_episode_plasma_units',
        order: 14,
        required: false,
        type: 'select',
        label: 'How many plasma units were transfused at T1?',
        name: '1_transfusion_episode_plasma_units',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: '1',
                label: '1'
            },
            {
                value: '2',
                label: '2'
            },
            {
                value: '3',
                label: '3'
            },
            
            {
                value: '4',
                label:'4'
            }
        ]
    },
    {
        id: '1_transfusion_episode_total_plasma_volume',
        order: 15,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 2000',
        label: 'What is the total volume of plasma transfused at T1?(ml)',
        name: '1_transfusion_episode_total_plasma_volume',
        visible: true,
        depends: undefined,
        choices: undefined,
    },
    {
        id: '1_1_plasma_unit_blood_donation_bkey',
        order: 16,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: 'max 255 characters',
        label: 'What is the donation number of the first plasma unit of the first episode?',
        name: '1_1_plasma_unit_blood_donation_bkey',
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '1'
                ]
            },
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '2'
                ]
            },
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '3'
                ]
            },
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '4'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: '1_2_plasma_unit_blood_donation_bkey',
        order: 17,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: 'max 255 characters',
        label: 'What is the donation number of the second plasma unit of the first episode?',
        name: '1_2_plasma_unit_blood_donation_bkey',
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '2'
                ]
            },
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '3'
                ]
            },
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '4'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: '1_3_plasma_unit_blood_donation_bkey',
        order: 18,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: 'max 255 characters',
        label: 'What is the donation number of the third plasma unit of the first episode?',
        name: '1_3_plasma_unit_blood_donation_bkey',
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '3'
                ]
            },
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '4'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: '1_4_plasma_unit_blood_donation_bkey',
        order: 19,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: 'max 255 characters',
        label: 'What is the donation number of the fourth plasma unit of the first episode?',
        name: '1_4_plasma_unit_blood_donation_bkey',
        visible: false,
        depends: [
            {
                id: '1_transfusion_episode_plasma_units',
                answers:[
                    '4'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: '1_transfusion_episode_adverse_reaction',
        order:20,
        required: false,
        type: 'multiselect',
        label: 'Were there any immediate adverse reaction(s) associated with T1?',
        name: '1_transfusion_episode_adverse_reaction',
        visible: true,
        depends: undefined,
        choices:[
            {
                value: false,
                label: 'None'
            },
            {
                value: false,
                label: 'Allergy'
            },
            {
                value: false,
                label: 'Anaphylactic'
            },
            {
                value: false,
                label: 'Thrombosis'
            },
            {
                value: false,
                label: 'Urticaria'
            },
            {
                value: false,
                label: 'Chills'
            },
            {
                value: false,
                label: 'Fever'
            },
            {
                value: false,
                label: 'TRALI'
            },
            {
                value: false,
                label: 'TACO'
            },
            {
                value: false,
                label: 'Antibody-Dependent Enhancement of infection (ADE)'
            },
            {
                value: false,
                label: 'other'
            }
        ]
    },
    {
        id: '1_transfusion_episode_adverse_reaction_if_other',
        order: 21,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: 'max 255 characters',
        label: 'Describe the other adverse reaction(s):',
        name: '1_transfusion_episode_adverse_reaction_if_other',
        visible: false,
        depends: [
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'other'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: '1_transfusion_episode_adverse_reaction_severity',
        order: 22,
        type: 'select',
        label: 'What was the severity of the adverse reaction(s) associated with T1?',
        name: '1_transfusion_episode_adverse_reaction_severity',
        visible: false,
        dependenciesType: 'or',
        depends:[
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Allergy'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Anaphylactic'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Thrombosis'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Urticaria'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Chills'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Fever'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'TRALI'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'TACO'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Antibody-Dependent Enhancement of infection (ADE)'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Other'
                ]
            }
        ],
        choices:[
            {
                value: 'minor',
                label: 'minor'
            },
            {
                value: 'moderate',
                label: 'moderate'
            },
            {
                value: 'severe',
                label: 'severe'
            },
            {
                value: 'death',
                label: 'death'
            }
        ]
    },
    {
        id: '1_transfusion_episode_adverse_reaction_imputability',
        order: 23,
        type: 'select',
        label: 'What is the imputability of the adverse reaction associated with T1?',
        name: '1_transfusion_episode_adverse_reaction_imputability',
        visible: false,
        dependenciesType: 'or',
        depends:[
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Allergy'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Anaphylactic'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Thrombosis'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Urticaria'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Chills'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Fever'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'TRALI'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'TACO'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Antibody-Dependent Enhancement of infection (ADE)'
                ]
            },
            {
                id: "1_transfusion_episode_adverse_reaction",
                answers:[
                    'Other'
                ]
            }
        ],
        choices:[
            {
                value: 'Imputability not assessable',
                label: 'Imputability not assessable'
            },
            {
                value: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion',
                label: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion'
            },
            {
                value: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion',
                label: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion'
            },
            {
                value: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion',
                label: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion'
            },
            {
                value: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion',
                label: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion'
            }
        ]
    },
    {
        id: '1_transfusion_episode_clinical_status_determined',
        order: 24,
        required: false,
        type: 'select',
        label: 'Is the clinical status following T1 (24h after transfusion) already determined?',
        name: '1_transfusion_episode_clinical_status_determined',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'not yet'
            }
        ]
    },
    {
        id: '1_transfusion_episode_clinical_status_interval',
        order: 25,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the number of days between onset of the symptoms and this assessment of the clinical status?',
        name: 'day_between_symptoms_assessment',
        visible: false,
        depends:[
            {
                id: '1_transfusion_episode_clinical_status_determined',
                answers: [
                    'True'
                ] 
            }
        ],
        choices: undefined
    },
    {
        id: '1_transfusion_episode_clinical_status',
        order: 26,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 8',
        label: 'What was the clinical status of the patient following T1 based on the WHO scale?',
        name: '1_transfusion_episode_clinical_status',
        visible: false,
        depends:[
            {
                id: '1_transfusion_episode_clinical_status_determined',
                answers: [
                    'True'
                ] 
            }
        ],
        choices: undefined
    },


        // Second Transfusion Episode (T2)
        {  
             id: '2_transfusion_process_transfusion_episode_count',
            order: 27,
            required: false,
            type: 'select',
            label: 'Is there a second transfusion episode?',
            name: '2_transfusion_process_transfusion_episode_count',
            visible: true,
            depends: undefined,
            choices:[
                {
                    value: 'yes',
                    label: 'yes'
                },
                {
                    value: 'no',
                    label: 'no'
                },
                {
                    value: 'not yet',
                    label: 'not yet'
                }
            ]
        },
        {
            id: '2_transfusion_episode_onset_symptoms_interval',
            order: 28,
            required: false,
            type: 'number',
            helperText: 'integer between 0 and 200',
            label: 'What was the number of days between the onset of symptoms and second transfusion?',
            name: '2_transfusion_episode_onset_symptoms_interval',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: undefined,
            choices: undefined
        },
        {
            id: '2_transfusion_episode_symptoms_severity',
            order: 29,
            required: false,
            type: 'number',
            helperText: 'integer between 0 and 8',
            label: 'What was the severity at the second transfusion episode, following the COVID-19 WHO scale?',
            name: '2_transfusion_episode_symptoms_severity',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: undefined,
            choices: undefined
            //PICTURE
        },
        {
            id: '2_transfusion_episode_plasma_units',
            order: 30,
            required: false,
            type: 'select',
            label: 'How many plasma units were transfused at T2?',
            name: '2_transfusion_episode_plasma_units',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: undefined,
            choices: [
                {
                    value: '1',
                    label: '1'
                },
                {
                    value: '2',
                    label: '2'
                },
                {
                    value: '3',
                    label: '3'
                },
                
                {
                    value: '4',
                    label:'4'
                }
            ]
        },
        {
            id: '2_transfusion_episode_total_plasma_volume',
            order: 31,
            required: false,
            type: 'number',
            helperText: 'integer between 0 and 2000',
            label: 'What is the total volume of plasma transfused at T2?(ml)',
            name: '2_transfusion_episode_total_plasma_volume',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: undefined,
            choices: undefined,
        },
        {
            id: '2_1_plasma_unit_blood_donation_bkey',
            order: 32,
            required: false,
            type: 'text',
            inputProps: {
                maxLength: 255
            },
            helperText: 'max 255 characters',
            label: 'What is the donation number of the first plasma unit of the second episode?(if applicable)',
            name: '2_1_plasma_unit_blood_donation_bkey',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            dependenciesType: 'or',
            depends: [
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '1'
                    ]
                },
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '2'
                    ]
                },
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '3'
                    ]
                },
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '4'
                    ]
                }
            ],
            choices: undefined
        },
        {
            id: '2_2_plasma_unit_blood_donation_bkey',
            order: 33,
            required: false,
            type: 'text',
            inputProps: {
                maxLength: 255
            },
            helperText: 'max 255 characters',
            label: 'What is the donation number of the second plasma unit of the second episode?(if applicable)',
            name: '2_2_plasma_unit_blood_donation_bkey',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            dependenciesType: 'or',
            depends: [
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '2'
                    ]
                },
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '3'
                    ]
                },
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '4'
                    ]
                }
            ],
            choices: undefined
        },
        {
            id: '2_3_plasma_unit_blood_donation_bkey',
            order: 34,
            required: false,
            type: 'text',
            inputProps: {
                maxLength: 255
            },
            helperText: 'max 255 characters',
            label: 'What is the donation number of the third plasma unit of the second episode?(if applicable)',
            name: '2_3_plasma_unit_blood_donation_bkey',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            dependenciesType: 'or',
            depends: [
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '3'
                    ]
                },
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '4'
                    ]
                }
            ],
            choices: undefined
        },
        {
            id: '2_4_plasma_unit_blood_donation_bkey',
            order: 35,
            required: false,
            type: 'text',
            inputProps: {
                maxLength: 255
            },
            helperText: 'max 255 characters',
            label: 'What is the donation number of the fourth plasma unit of the second episode?(if applicable)',
            name: '2_4_plasma_unit_blood_donation_bkey',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: [
                {
                    id: '2_transfusion_episode_plasma_units',
                    answers:[
                        '4'
                    ]
                }
            ],
            choices: undefined
        },
        {
            id: '2_transfusion_episode_adverse_reaction',
            order:36,
            required: false,
            type: 'multiselect',
            label: 'Were there any immediate adverse reaction(s) associated with T2?',
            name: '2_transfusion_episode_adverse_reaction',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: undefined,
            choices:[
                {
                    value: false,
                    label: 'None'
                },
                {
                    value: false,
                    label: 'Allergy'
                },
                {
                    value: false,
                    label: 'Anaphylactic'
                },
                {
                    value: false,
                    label: 'Thrombosis'
                },
                {
                    value: false,
                    label: 'Urticaria'
                },
                {
                    value: false,
                    label: 'Chills'
                },
                {
                    value: false,
                    label: 'Fever'
                },
                {
                    value: false,
                    label: 'TRALI'
                },
                {
                    value: false,
                    label: 'TACO'
                },
                {
                    value: false,
                    label: 'Antibody-Dependent Enhancement of infection (ADE)'
                },
                {
                    value: false,
                    label: 'other'
                }
            ]
        },
        {
            id: '2_transfusion_episode_adverse_reaction_if_other',
            order: 37,
            required: false,
            type: 'text',
            inputProps: {
                maxLength: 255
            },
            helperText: 'max 255 characters',
            label: 'Describe the other adverse reaction(s):',
            name: '2_transfusion_episode_adverse_reaction_if_other',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: [
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'other'
                    ]
                }
            ],
            choices: undefined
        },
        {
            id: '2_transfusion_episode_adverse_reaction_severity',
            order: 38,
            type: 'select',
            label: 'What was the severity of the adverse reaction(s) associated with T2?',
            name: '2_transfusion_episode_adverse_reaction_severity',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            dependenciesType: 'or',
            depends:[
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Allergy'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Anaphylactic'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Thrombosis'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Urticaria'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Chills'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Fever'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'TRALI'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'TACO'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Antibody-Dependent Enhancement of infection (ADE)'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Other'
                    ]
                }
            ],
            choices:[
                {
                    value: 'minor',
                    label: 'minor'
                },
                {
                    value: 'moderate',
                    label: 'moderate'
                },
                {
                    value: 'severe',
                    label: 'severe'
                },
                {
                    value: 'death',
                    label: 'death'
                }
            ]
        },
        {
            id: '2_transfusion_episode_adverse_reaction_imputability',
            order: 39,
            type: 'select',
            label: 'What is the imputability of the adverse reaction associated with T2?',
            name: '2_transfusion_episode_adverse_reaction_imputability',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            dependenciesType: 'or',
            depends:[
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Allergy'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Anaphylactic'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Thrombosis'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Urticaria'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Chills'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Fever'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'TRALI'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'TACO'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Antibody-Dependent Enhancement of infection (ADE)'
                    ]
                },
                {
                    id: "2_transfusion_episode_adverse_reaction",
                    answers:[
                        'Other'
                    ]
                }
            ],
            choices:[
                {
                    value: 'Imputability not assessable',
                    label: 'Imputability not assessable'
                },
                {
                    value: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion',
                    label: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion'
                },
                {
                    value: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion',
                    label: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion'
                },
                {
                    value: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion',
                    label: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion'
                },
                {
                    value: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion',
                    label: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion'
                }
            ]
        },
        {
            id: '2_transfusion_episode_clinical_status_determined',
            order: 40,
            required: false,
            type: 'select',
            label: 'Is the clinical status following T2 (24h after transfusion) already determined?',
            name: '2_transfusion_episode_clinical_status_determined',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends: undefined,
            choices: [
                {
                    value: 'True',
                    label: 'yes'
                },
                {
                    value: 'False',
                    label: 'not yet'
                }
            ]
        },
        {
            id: '2_transfusion_episode_clinical_status_interval',
            order: 41,
            required: false,
            type: 'number',
            helperText: 'integer between 0 and 200',
            label: 'What was the number of days between onset of the symptoms and this assessment of the clinical status?',
            name: '2_transfusion_episode_clinical_status_interval',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends:[
                {
                    id: '2_transfusion_episode_clinical_status_determined',
                    answers: [
                        'True'
                    ] 
                }
            ],
            choices: undefined
        },
        {
            id: '2_transfusion_episode_clinical_status',
            order: 42,
            required: false,
            type: 'number',
            helperText: 'integer between 0 and 8',
            label: 'What was the clinical status of the patient following T2 based on the WHO scale?',
            name: '2_transfusion_episode_clinical_status',
            visible: false,
            transfusionDepends: [{
                id: '2_transfusion_process_transfusion_episode_count',
                answers:[
                    'yes'
                ]
            }],
            depends:[
                {
                    id: '2_transfusion_episode_clinical_status_determined',
                    answers: [
                        'True'
                    ] 
                }
            ],
            choices: undefined
        },

               // thir Transfusion Episode (T3)
               {  
                id: '3_transfusion_process_transfusion_episode_count',
               order: 43,
               required: false,
               type: 'select',
               label: 'Is there a third transfusion episode?',
               name: '3_transfusion_process_transfusion_episode_count',
               visible: false,
               transfusionDepends: [{
                    id: '2_transfusion_process_transfusion_episode_count',
                    answers:[
                        'yes'
                    ]
                }],
               depends:undefined,
               choices:[
                   {
                       value: 'yes',
                       label: 'yes'
                   },
                   {
                       value: 'no',
                       label: 'no'
                   },
                   {
                       value: 'not yet',
                       label: 'not yet'
                   }
               ]
           },
           {
               id: '3_transfusion_episode_onset_symptoms_interval',
               order: 44,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 200',
               label: 'What was the number of days between the onset of symptoms and third transfusion?',
               name: '3_transfusion_episode_onset_symptoms_interval',
               visible: false,
               transfusionDepends: [
                   {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
               depends:undefined,
               choices: undefined
           },
           {
               id: '3_transfusion_episode_symptoms_severity',
               order: 45,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 8',
               label: 'What was the severity at the third transfusion episode, following the COVID-19 WHO scale?',
               name: '3_transfusion_episode_symptoms_severity',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
               depends:undefined,
               choices: undefined
               //PICTURE
           },
           {
               id: '3_transfusion_episode_plasma_units',
               order: 46,
               required: false,
               type: 'select',
               label: 'How many plasma units were transfused at T3?',
               name: '3_transfusion_episode_plasma_units',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                depends:undefined,
               choices: [
                   {
                       value: '1',
                       label: '1'
                   },
                   {
                       value: '2',
                       label: '2'
                   },
                   {
                       value: '3',
                       label: '3'
                   },
                   
                   {
                       value: '4',
                       label:'4'
                   }
               ]
           },
           {
               id: '3_transfusion_episode_total_plasma_volume',
               order: 47,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 2000',
               label: 'What is the total volume of plasma transfused at T3?(ml)',
               name: '3_transfusion_episode_total_plasma_volume',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                depends:undefined,
               choices: undefined,
           },
           {
               id: '3_1_plasma_unit_blood_donation_bkey',
               order: 48,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the first plasma unit of the third episode?',
               name: '3_1_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                dependenciesType: 'or',
                depends: [
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '1'
                        ]
                    },
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '2'
                        ]
                    },
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '3'
                        ]
                    },
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '4'
                        ]
                    }
                ],
               choices: undefined
           },
           {
               id: '3_2_plasma_unit_blood_donation_bkey',
               order: 49,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the second plasma unit of the third episode?',
               name: '3_2_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                dependenciesType: 'or',
                depends: [
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '2'
                        ]
                    },
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '3'
                        ]
                    },
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '4'
                        ]
                    }
                ],
               choices: undefined
           },
           {
               id: '3_3_plasma_unit_blood_donation_bkey',
               order: 50,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the third plasma unit of the third episode?',
               name: '3_3_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                dependenciesType: 'or',
                depends: [
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '3'
                        ]
                    },
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '4'
                        ]
                    }
                ],
               choices: undefined
           },
           {
               id: '3_4_plasma_unit_blood_donation_bkey',
               order: 51,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the fourth plasma unit of the third episode?',
               name: '3_4_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                depends: [
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '4'
                        ]
                    }
                ],
               choices: undefined
           },
           {
               id: '3_transfusion_episode_adverse_reaction',
               order:52,
               required: false,
               type: 'multiselect',
               label: 'Were there any immediate adverse reaction(s) associated with T3?',
               name: '3_transfusion_episode_adverse_reaction',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                depends:undefined,
               choices:[
                   {
                       value: false,
                       label: 'None'
                   },
                   {
                       value: false,
                       label: 'Allergy'
                   },
                   {
                       value: false,
                       label: 'Anaphylactic'
                   },
                   {
                       value: false,
                       label: 'Thrombosis'
                   },
                   {
                       value: false,
                       label: 'Urticaria'
                   },
                   {
                       value: false,
                       label: 'Chills'
                   },
                   {
                       value: false,
                       label: 'Fever'
                   },
                   {
                       value: false,
                       label: 'TRALI'
                   },
                   {
                       value: false,
                       label: 'TACO'
                   },
                   {
                       value: false,
                       label: 'Antibody-Dependent Enhancement of infection (ADE)'
                   },
                   {
                       value: false,
                       label: 'other'
                   }
               ]
           },
           {
               id: '3_transfusion_episode_adverse_reaction_if_other',
               order: 53,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'Describe the other adverse reaction(s):',
               name: '3_transfusion_episode_adverse_reaction_if_other',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
               depends: [
                   {
                       id: "3_transfusion_episode_adverse_reaction",
                       answers:[
                           'other'
                       ]
                   }
               ],
               choices: undefined
           },
           {
               id: '3_transfusion_episode_adverse_reaction_severity',
               order: 54,
               type: 'select',
               label: 'What was the severity of the adverse reaction(s) associated with T3?',
               name: '3_transfusion_episode_adverse_reaction_severity',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                dependenciesType: 'or',
                depends:[
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Allergy'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Anaphylactic'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Thrombosis'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Urticaria'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Chills'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Fever'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'TRALI'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'TACO'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Antibody-Dependent Enhancement of infection (ADE)'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Other'
                        ]
                    }
                ],
               choices:[
                {
                    value: 'minor',
                    label: 'minor'
                },
                {
                    value: 'moderate',
                    label: 'moderate'
                },
                {
                    value: 'severe',
                    label: 'severe'
                },
                {
                    value: 'death',
                    label: 'death'
                }
            ]
           },
           {
               id: '3_transfusion_episode_adverse_reaction_imputability',
               order: 55,
               type: 'select',
               label: 'What is the imputability of the adverse reaction associated with T3?',
               name: '3_transfusion_episode_adverse_reaction_imputability',
               visible: false,
               transfusionDepends: [
                 {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                dependenciesType: 'or',
                depends:[
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Allergy'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Anaphylactic'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Thrombosis'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Urticaria'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Chills'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Fever'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'TRALI'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'TACO'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Antibody-Dependent Enhancement of infection (ADE)'
                        ]
                    },
                    {
                        id: "3_transfusion_episode_adverse_reaction",
                        answers:[
                            'Other'
                        ]
                    }
                ],
               choices:[
                   {
                       value: 'Imputability not assessable',
                       label: 'Imputability not assessable'
                   },
                   {
                       value: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion',
                       label: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion'
                   },
                   {
                       value: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion',
                       label: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion'
                   },
                   {
                       value: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion',
                       label: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion'
                   },
                   {
                       value: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion',
                       label: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion'
                   }
               ]
           },
           {
               id: '3_transfusion_episode_clinical_status_determined',
               order: 56,
               required: false,
               type: 'select',
               label: 'Is the clinical status following T3 (24h after transfusion) already determined?',
               name: '3_transfusion_episode_clinical_status_determined',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
                depends:undefined,
               choices: [
                   {
                       value: 'True',
                       label: 'yes'
                   },
                   {
                       value: 'False',
                       label: 'not yet'
                   }
               ]
           },
           {
               id: '3_transfusion_episode_clinical_status_interval',
               order: 57,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 200',
               label: 'What was the number of days between onset of the symptoms and this assessment of the clinical status?',
               name: 'day_between_symptoms_assessment',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
               depends:[
                   {
                       id: '3_transfusion_episode_clinical_status_determined',
                       answers: [
                           'True'
                       ] 
                   }
               ],
               choices: undefined
           },
           {
               id: '3_transfusion_episode_clinical_status',
               order: 58,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 8',
               label: 'What was the clinical status of the patient following T3 based on the WHO scale?',
               name: '3_transfusion_episode_clinical_status',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
             ],
               depends:[
                   {
                       id: '3_transfusion_episode_clinical_status_determined',
                       answers: [
                           'True'
                       ] 
                   }
               ],
               choices: undefined
           },
  
            // fourth Transfusion Episode (T4)
           {  
                id: '4_transfusion_process_transfusion_episode_count',
               order: 59,
               required: false,
               type: 'select',
               label: 'Is there a fourth transfusion episode?',
               name: '4_transfusion_process_transfusion_episode_count',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
               depends:undefined,
            choices:[
                   {
                       value: 'yes',
                       label: 'yes'
                   },
                   {
                       value: 'no',
                       label: 'no'
                   },
                   {
                       value: 'not yet',
                       label: 'not yet'
                   }
               ]
           },
           {
               id: '4_transfusion_episode_onset_symptoms_interval',
               order: 60,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 200',
               label: 'What was the number of days between the onset of symptoms and fourth transfusion?',
               name: '4_transfusion_episode_onset_symptoms_interval',
               visible: false,
               transfusionDepends: [
                            {
                                id: '2_transfusion_process_transfusion_episode_count',
                                answers:[
                                    'yes'
                                ],   
                            },
                            {
                                id: '3_transfusion_process_transfusion_episode_count',
                                answers:[
                                    'yes'
                                ]
                            },
                            {
                                id: '4_transfusion_process_transfusion_episode_count',
                                answers:[
                                    'yes'
                                ]
                            }
                    ],
               depends: undefined,
               choices: undefined
           },
           {
               id: '4_transfusion_episode_symptoms_severity',
               order: 61,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 8',
               label: 'What was the severity at the fourth transfusion episode, following the COVID-19 WHO scale?',
               name: '4_transfusion_episode_symptoms_severity',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                depends: undefined,
               choices: undefined
               //PICTURE
           },
           {
               id: '4_transfusion_episode_plasma_units',
               order: 62,
               required: false,
               type: 'select',
               label: 'How many plasma units were transfused at T4?',
               name: '4_transfusion_episode_plasma_units',
               visible: false,
               transfusionDepends: [
                            {
                                id: '2_transfusion_process_transfusion_episode_count',
                                answers:[
                                    'yes'
                                ],   
                            },
                            {
                                id: '3_transfusion_process_transfusion_episode_count',
                                answers:[
                                    'yes'
                                ]
                            },
                            {
                                id: '4_transfusion_process_transfusion_episode_count',
                                answers:[
                                    'yes'
                                ]
                            }
                    ],
                depends: undefined,
               choices: [
                   {
                       value: '1',
                       label: '1'
                   },
                   {
                       value: '2',
                       label: '2'
                   },
                   {
                       value: '3',
                       label: '3'
                   },
                   
                   {
                       value: '4',
                       label:'4'
                   }
               ]
           },
           {
               id: '4_transfusion_episode_total_plasma_volume',
               order: 63,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 2000',
               label: 'What is the total volume of plasma transfused at T4?(ml)',
               name: '4_transfusion_episode_total_plasma_volume',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                 ],
                depends: undefined,
               choices: undefined,
           },
           {
               id: '4_1_plasma_unit_blood_donation_bkey',
               order: 64,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the first plasma unit of the fourth episode?',
               name: '4_1_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                    ],
                    dependenciesType: 'or',
                    depends: [
                        {
                            id: '4_transfusion_episode_plasma_units',
                            answers:[
                                '1'
                            ]
                        },
                        {
                            id: '4_transfusion_episode_plasma_units',
                            answers:[
                                '2'
                            ]
                        },
                        {
                            id: '4_transfusion_episode_plasma_units',
                            answers:[
                                '3'
                            ]
                        },
                        {
                            id: '4_transfusion_episode_plasma_units',
                            answers:[
                                '4'
                            ]
                        }
                    ],
               choices: undefined
           },
           {
               id: '4_2_plasma_unit_blood_donation_bkey',
               order: 65,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the second plasma unit of the fourth episode?',
               name: '4_2_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                dependenciesType: 'or',
                depends: [
                    
                    {
                        id: '4_transfusion_episode_plasma_units',
                        answers:[
                            '2'
                        ]
                    },
                    {
                        id: '4_transfusion_episode_plasma_units',
                        answers:[
                            '3'
                        ]
                    },
                    {
                        id: '4_transfusion_episode_plasma_units',
                        answers:[
                            '4'
                        ]
                    }
                ],
               choices: undefined
           },
           {
               id: '4_3_plasma_unit_blood_donation_bkey',
               order: 66,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the third plasma unit of the fourth episode?',
               name: '4_3_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                dependenciesType: 'or',
                depends: [

                    {
                        id: '4_transfusion_episode_plasma_units',
                        answers:[
                            '3'
                        ]
                    },
                    {
                        id: '4_transfusion_episode_plasma_units',
                        answers:[
                            '4'
                        ]
                    }
                ],
               choices: undefined
           },
           {
               id: '4_4_plasma_unit_blood_donation_bkey',
               order: 67,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'What is the donation number of the fourth plasma unit of the fourth episode?',
               name: '4_4_plasma_unit_blood_donation_bkey',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                depends: [
                    {
                        id: '3_transfusion_episode_plasma_units',
                        answers:[
                            '4'
                        ]
                    }
                ],
               choices: undefined
           },
           {
               id: '4_transfusion_episode_adverse_reaction',
               order: 68,
               required: false,
               type: 'multiselect',
               label: 'Were there any immediate adverse reaction(s) associated with T4?',
               name: '4_transfusion_episode_adverse_reaction',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                depends: undefined,
               choices:[
                   {
                       value: false,
                       label: 'None'
                   },
                   {
                       value: false,
                       label: 'Allergy'
                   },
                   {
                       value: false,
                       label: 'Anaphylactic'
                   },
                   {
                       value: false,
                       label: 'Thrombosis'
                   },
                   {
                       value: false,
                       label: 'Urticaria'
                   },
                   {
                       value: false,
                       label: 'Chills'
                   },
                   {
                       value: false,
                       label: 'Fever'
                   },
                   {
                       value: false,
                       label: 'TRALI'
                   },
                   {
                       value: false,
                       label: 'TACO'
                   },
                   {
                       value: false,
                       label: 'Antibody-Dependent Enhancement of infection (ADE)'
                   },
                   {
                       value: false,
                       label: 'other'
                   }
               ]
           },
           {
               id: '4_transfusion_episode_adverse_reaction_if_other',
               order: 69,
               required: false,
               type: 'text',
               inputProps: {
                   maxLength: 255
               },
               helperText: 'max 255 characters',
               label: 'Describe the other adverse reaction(s):',
               name: '4_transfusion_episode_adverse_reaction_if_other',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
               depends: [
                   {
                       id: "4_transfusion_episode_adverse_reaction",
                       answers:[
                           'other'
                       ]
                   }
               ],
               choices: undefined
           },
           {
               id: '4_transfusion_episode_adverse_reaction_severity',
               order: 70,
               type: 'select',
               label: 'What was the severity of the adverse reaction(s) associated with T4?',
               name: '4_transfusion_episode_adverse_reaction_severity',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                dependenciesType: 'or',
                depends:[
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Allergy'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Anaphylactic'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Thrombosis'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Urticaria'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Chills'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Fever'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'TRALI'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'TACO'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Antibody-Dependent Enhancement of infection (ADE)'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Other'
                        ]
                    }
                ],
               choices:[
                {
                    value: 'minor',
                    label: 'minor'
                },
                {
                    value: 'moderate',
                    label: 'moderate'
                },
                {
                    value: 'severe',
                    label: 'severe'
                },
                {
                    value: 'death',
                    label: 'death'
                }
            ]
               },
           {
               id: '4_transfusion_episode_adverse_reaction_imputability',
               order: 71,
               type: 'select',
               label: 'What is the imputability of the adverse reaction associated with T4?',
               name: '4_transfusion_episode_adverse_reaction_imputability',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                dependenciesType: 'or',
                depends:[
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Allergy'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Anaphylactic'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Thrombosis'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Urticaria'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Chills'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Fever'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'TRALI'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'TACO'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Antibody-Dependent Enhancement of infection (ADE)'
                        ]
                    },
                    {
                        id: "4_transfusion_episode_adverse_reaction",
                        answers:[
                            'Other'
                        ]
                    }
                ],
               choices:[
                   {
                       value: 'Imputability not assessable',
                       label: 'Imputability not assessable'
                   },
                   {
                       value: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion',
                       label: 'Imputability 0 (excluded): available evidence that the adverse reaction cannot be imputed to the transfusion'
                   },
                   {
                       value: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion',
                       label: 'Imputability 1 (possible): absence of evidence that the adverse reaction cannot be imputed to the transfusion'
                   },
                   {
                       value: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion',
                       label: 'Imputability 2 (probable): available evidence that the adverse reaction might be imputed to the transfusion'
                   },
                   {
                       value: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion',
                       label: 'Imputability 3 (certain): available evidence that the adverse reaction can be imputed to the transfusion'
                   }
               ]
           },
           {
               id: '4_transfusion_episode_clinical_status_determined',
               order: 72,
               required: false,
               type: 'select',
               label: 'Is the clinical status following T4 (24h after transfusion) already determined?',
               name: '4_transfusion_episode_clinical_status_determined',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
                depends: undefined,
               choices: [
                   {
                       value: 'True',
                       label: 'yes'
                   },
                   {
                       value: 'False',
                       label: 'not yet'
                   }
               ]
           },
           {
               id: '4_transfusion_episode_clinical_status_interval',
               order: 73,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 200',
               label: 'What was the number of days between onset of the symptoms and this assessment of the clinical status?',
               name: 'day_between_symptoms_assessment',
               visible: false,
               transfusionDepends: [
                    {
                        id: '2_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ],   
                    },
                    {
                        id: '3_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    },
                    {
                        id: '4_transfusion_process_transfusion_episode_count',
                        answers:[
                            'yes'
                        ]
                    }
                ],
               depends:[
                   {
                       id: '4_transfusion_episode_clinical_status_determined',
                       answers: [
                           'True'
                       ] 
                   }
               ],
               choices: undefined
           },
           {
               id: '4_transfusion_episode_clinical_status',
               order: 74,
               required: false,
               type: 'number',
               helperText: 'integer between 0 and 8',
               label: 'What was the clinical status of the patient following T4 based on the WHO scale?',
               name: '4_transfusion_episode_clinical_status',
               visible: false,
               transfusionDepends: [
                        {
                            id: '2_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ],   
                        },
                        {
                            id: '3_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        },
                        {
                            id: '4_transfusion_process_transfusion_episode_count',
                            answers:[
                                'yes'
                            ]
                        }
                ],
               depends:[
                   {
                       id: '4_transfusion_episode_clinical_status_determined',
                       answers: [
                           'True'
                       ] 
                    }
               ],
               choices: undefined
           },
   

    // Effectiveness of the treatment
    {
        id: 'patient_received_additional_treatment',
        order: 75,
        required: false,
        type: 'select',
        label: 'Did the patient receive any additional non-experimental treatments, other than supportive care?',
        name: 'patient_received_additional_treatment',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'no'
            },
            {
                value: 'false',
                label: 'information not yet available'
            }
        ]
    },
    {
        id: 'patient_additional_treatments',
        order: 76,
        required: false,
        type: 'multiselect',
        label: 'What was the type of treatment?',
        name: 'patient_additional_treatments',
        visible: false,
        depends: [
            {
                id: 'patient_received_additional_treatment',
                answers: [
                    'True'
                ]
            }
        ],
        choices:[
            {
                value: false,
                label: 'Anti-viral drugs'
            },
            {
                value: false,
                label: 'Immuno-modulatory agents'
            },
            {
                value: false,
                label: 'Anticoagulants'
            },
            {
                value: false,
                label: 'Steroids before transfusion'
            },
            {
                value: false,
                label: 'Steroids during transfusion'
            },
            {
                value: false,
                label: 'Steroids after transfusion'
            },
            {
                value: false,
                label: 'Anti-Cytokines (R) therapy'
            },
            {
                value: false,
                label: 'Other'
            }
        ]
    },
    {
        id: 'patient_additional_treatments_if_other',
        order: 77,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        label: 'Describe the other performed associated treatment(s):',
        name: 'patient_additional_treatments_if_other',
        visible: false,
        depends: [
            {
                id: 'patient_additional_treatments',
                answers: [
                    'Other'
                ]
            },
            {
                id: 'patient_received_additional_treatment',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },

    // attesa verdetto fra
    // inizio possibile ripetizione
    {
        id: 'patient_clinical_status_D7_determined',
        order: 78,
        required: false,
        type: 'select',
        label: 'Is the clinical status one week after T1 (preferentially at D7) already determined?',
        name: 'patient_clinical_status_D7_determined',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'not yet'
            }
        ]
    },
    {
        id: 'patient_clinical_status_D7_onset_interval',
        order: 79 /*30*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the number of days between the onset of the symptoms and this assessment of the clinical status?',
        name: 'patient_clinical_status_D7_onset_interval',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D7_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D7',
        order: 80 /*31*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 8',
        label: 'What was the clinical status of the patient based on the WHO scale one week after T1?',
        name: 'patient_clinical_status_D7',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D7_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D7_RTPCR',
        order: 81 /*32*/,
        required: false,
        type: 'select',
        label: 'What is the RT-PCR status of the patient one week after T1?',
        name: 'patient_clinical_status_D7_RTPCR',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D7_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: [
            {
                value: 'positive',
                label: 'positive'
            },
            {
                value: 'negative',
                label: 'negative'
            },
            {
                value: 'not determined',
                label: 'not determined'
            }
        ]
    },
    
// fine possibile ripetizione
// attesa verdetto fra
    // inizio possibile ripetizione
    {
        id: 'patient_clinical_status_D14_determined',
        order: 82,
        required: false,
        type: 'select',
        label: 'Is the clinical status two week after T1 (preferentially at D14) already determined?',
        name: 'patient_clinical_status_D14_determined',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'not yet'
            }
        ]
    },
    {
        id: 'patient_clinical_status_D14_onset_interval',
        order: 83 /*30*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the number of days between the onset of the symptoms and this assessment of the clinical status?',
        name: 'patient_clinical_status_D14_onset_interval',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D14_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D14',
        order: 84 /*31*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 8',
        label: 'What was the clinical status of the patient based on the WHO scale two weeks after T1?',
        name: 'patient_clinical_status_D14',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D14_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D14_RTPCR',
        order: 85 /*32*/,
        required: false,
        type: 'select',
        label: 'What is the RT-PCR status of the patient two weeks after T1?',
        name: 'patient_clinical_status_D14_RTPCR',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D14_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: [
            {
                value: 'positive',
                label: 'positive'
            },
            {
                value: 'negative',
                label: 'negative'
            },
            {
                value: 'not determined',
                label: 'not determined'
            }
        ]
    },
    
// fine possibile ripetizione
// attesa verdetto fra
    // inizio possibile ripetizione
    {
        id: 'patient_clinical_status_D21_determined',
        order: 86,
        required: false,
        type: 'select',
        label: 'Is the clinical status three week after T1 (preferentially at D21) already determined?',
        name: 'patient_clinical_status_D21_determined',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'not yet'
            }
        ]
    },
    {
        id: 'patient_clinical_status_D21_onset_interval',
        order: 87 /*30*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the number of days between the onset of the symptoms and this assessment of the clinical status?',
        name: 'patient_clinical_status_D21_onset_interval',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D21_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D21',
        order: 88 /*31*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 8',
        label: 'What was the clinical status of the patient based on the WHO scale three weeks after T1?',
        name: 'patient_clinical_status_D21',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D21_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D21_RTPCR',
        order: 89 /*32*/,
        required: false,
        type: 'select',
        label: 'What is the RT-PCR status of the patient three weeks after T1?',
        name: 'patient_clinical_status_D21_RTPCR',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D21_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: [
            {
                value: 'positive',
                label: 'positive'
            },
            {
                value: 'negative',
                label: 'negative'
            },
            {
                value: 'not determined',
                label: 'not determined'
            }
        ]
    },
    
// fine possibile ripetizione
// attesa verdetto fra
    // inizio possibile ripetizione
    {
        id: 'patient_clinical_status_D28_determined',
        order: 90,
        required: false,
        type: 'select',
        label: 'Is the clinical status four week after T1 (preferentially at D28) already determined?',
        name: 'patient_clinical_status_D28_determined',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'not yet'
            }
        ]
    },
    {
        id: 'patient_clinical_status_D28_onset_interval',
        order: 91 /*30*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the number of days between the onset of the symptoms and this assessment of the clinical status?',
        name: 'patient_clinical_status_D28_onset_interval',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D28_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D28',
        order: 92 /*31*/,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 8',
        label: 'What was the clinical status of the patient based on the WHO scale four weeks after T1?',
        name: 'patient_clinical_status_D28',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D28_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_clinical_status_D28_RTPCR',
        order: 93 /*32*/,
        required: false,
        type: 'select',
        label: 'What is the RT-PCR status of the patient four weeks after T1?',
        name: 'patient_clinical_status_D28_RTPCR',
        visible: false,
        depends: [
            {
                id: 'patient_clinical_status_D28_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: [
            {
                value: 'positive',
                label: 'positive'
            },
            {
                value: 'negative',
                label: 'negative'
            },
            {
                value: 'not determined',
                label: 'not determined'
            }
        ]
    },
    
// fine possibile ripetizione

    {
        id: 'patient_final_clinical_status_determined',
        order: 94,
        required: false,
        type: 'select',
        label: 'Is the final clinical status already determined?',
        name: 'patient_final_clinical_status_determined',
        visible: true,
        depends: undefined,
        choices: [
            {
                value: 'True',
                label: 'yes'
            },
            {
                value: 'False',
                label: 'not yet'
            }
        ]
    },
    {
        id: 'patient_final_clinical_status_onset_interval',
        order: 95,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the number of days between the final assessment of the clinical status and the onset of the symptoms?',
        name: 'patient_final_clinical_status_onset_interval',
        visible: false,
        depends:[
            {
                id: 'patient_final_clinical_status_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_hospitalization_duration',
        order: 96,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 200',
        label: 'What was the duration of the hospitalization? Please specify the number of days between admission to discharge:',
        name: 'patient_hospitalization_duration',
        visible: false,
        depends:[
            {
                id: 'patient_final_clinical_status_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_final_clinical_status',
        order: 97,
        required: false,
        type: 'number',
        helperText: 'integer between 0 and 8',
        label: 'What is the clinical status of the patient based on the WHO scale?',
        name: 'patient_final_clinical_status',
        visible: false,
        depends:[
            {
                id: 'patient_final_clinical_status_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'patient_final_clinical_status_RTPCR',
        order: 98,
        required: false,
        type: 'select',
        label: 'What is the RT-PCR status of the patient at the final assessment?',
        name: 'patient_final_clinical_status_RTPCR',
        visible: false,
        depends:[
            {
                id: 'patient_final_clinical_status_determined',
                answers: [
                    'True'
                ]
            }
        ],
        choices: [
            {
                value: 'positive',
                label: 'positive'
            },
            {
                value: 'negative',
                label: 'negative'
            },
            {
                value: 'not determined',
                label: 'not determined'
            }
        ]
    },
    {
        id: 'patient_has_long_term_follow_up',
        order: 99,
        required: false,
        type: 'select',
        label: 'Will there be any long term follow-up?',
        name: 'patient_has_long_term_follow_up',
        visible: true,
        depends: undefined,
        choices: [
            {
               value: 'True',
               label: 'yes' 
            },
            {
                value: 'False',
                label: 'no'
            },
            {
                value: 'false',
                label: 'not yet known'
            }
        ]
    },
    {
        id: 'patient_follow_up_proces',
        order: 100,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        label: 'Describe the follow-up process:',
        name: 'patient_follow_up_proces',
        visible: false,
        depends: [
            {
                id: 'patient_has_long_term_follow_up',
                answers: [
                    'True'
                ] 
            }
        ],
        choices: undefined
    }

]

export default FORM_FIELDS2
