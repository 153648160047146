import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import './CustomInput.css'

export default class CustomInput extends Component {
    render() {

        return (
            <TextField
                id={this.props.id} 
                key={this.props.key}
                required={this.props.required}
                type={this.props.type}
                label={this.props.label}
                name={this.props.name}
                value={this.props.value}
                disabled={this.props.disabled}
                inputProps={this.props.inputProps}
                helperText={this.props.helperText}
                InputLabelProps={this.props.InputLabelProps}
                onChange={this.props.onChange}
                pattern={this.props.pattern}
                onKeyDown={this.props.onKeyDown}
                className="inputStyle"
            />
        )
    }
}

CustomInput.propTypes = {
    id: PropTypes.any,
    required: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func
};