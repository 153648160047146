import { Auth } from 'aws-amplify';

const AMPLIFY_CONFIG={
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_AMPLIFY_NAME,
                endpoint: process.env.REACT_APP_AMPLIFY_ENDPOINT,
                custom_header: async () => {
                    const session = await Auth.currentSession();
                    return {AccessToken: session.getAccessToken().getJwtToken(), IdToken: session.getIdToken().getJwtToken()}
                },
            }
        ]
    }
}

export default AMPLIFY_CONFIG