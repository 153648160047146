import React, { Component } from 'react';
import { 
    ExpansionPanel, 
    ExpansionPanelSummary, 
    ExpansionPanelDetails, 
    Typography, 
    CircularProgress,
    Box,
    Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { postData } from '../../utils/api'
import PATH from '../../constants/api'
import CustomTrialForm from '../CustomTrialForm/CustomTrialForm'
import CustomInput from '../Base/CustomInput/CustomInput'
import CustomButton from '../Base/CustomButton/CustomButton'
import { Auth, Storage } from 'aws-amplify';

const PAGE_SIZE = 30

class CustomExtractPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            disabled: false,
        }

        this.extractDonation = this.extractDonation.bind(this)
        this.extractPatient = this.extractPatient.bind(this)
    }

    componentDidMount = async () => {
    /*try  {
            const data = await fetchData(PATH.test.path)
            const paged = this.addItem(data.body.entries)
            this.setState(prevState => {
                return {
                    donations: data.body.entries,
                    visibleDonations: prevState.visibleDonations.concat(paged),
                    pageIndex: prevState.pageIndex + PAGE_SIZE
                }
            })
        } catch(error) {
            console.log(error)
        }*/
    }
/*
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.extractDonation()
        }
    }
*/
    extractDonation = async () => {
        this.setState({
            disabled: true
        })
        const UserInfo = await Auth.currentUserInfo()
        //console.log(UserInfo.id)
        //console.log("how do i do this code thing again")
        const payload = {}
        payload['userid'] = UserInfo.id
        payload['requesting'] = 'donations'
        const result = await postData(PATH.test.donation_extract, payload)
        if (result.statusCode === 200) {
            //console.log("s3 key = ", result.body)
            var extract = await Storage.get(result.body, { level: 'private' })
            var a = document.createElement("a");
            a.href = extract;
            var fileName = extract.split("/").pop();
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(extract);
            a.remove();
            this.setState({
                disabled: false
            })
        }
        else{
            this.setState({
                disabled: false
            })
            this.props.showAlert('error', result.errorMessage)
        }
}

    extractPatient = async () => {
        this.setState({
            disabled: true
        })
        const UserInfo = await Auth.currentUserInfo()
        //console.log(UserInfo.id)
        //console.log("how do i do this code thing again")
        const payload = {}
        payload['userid'] = UserInfo.id
        payload['requesting'] = 'patients'
        const result = await postData(PATH.test.donation_extract, payload)
        if (result.statusCode === 200) {
            console.log("s3 key = ", result.body)
            var extract = await Storage.get(result.body, { level: 'private' })
            var a = document.createElement("a");
            a.href = extract;
            var fileName = extract.split("/").pop();
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(extract);
            a.remove();
            this.setState({
                disabled: false
            })
        }
        else{
            this.setState({
                disabled: false
            })
            this.props.showAlert('error', result.errorMessage)
        }
    }

    render() {
        return (
            <>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Grid container justify="center" alignItems="flex-end">
                            <Grid item xs={3} sm={2} lg={1}>
                                <Grid container justify="flex-end">
                                {this.state.disabled ? 
                                    <CircularProgress 
                                        size={30}
                                    />
                                : 
                                    <CustomButton 
                                        disabled={this.state.disabled}
                                        variant="contained"
                                        color="secondary"
                                        text="Extract my donation data"
                                        onClick={this.extractDonation}
                                    />
                                }
                                    
                                </Grid>
                            </Grid>
                            {<Grid item xs={3} sm={2} lg={1}>
                                <Grid container justify="flex-end">
                                {this.state.disabled ? 
                                    <CircularProgress 
                                        size={30}
                                    />
                                : 
                                    <CustomButton 
                                        disabled={this.state.disabled}
                                        variant="contained"
                                        color="secondary"
                                        text="Extract my patient data"
                                        onClick={this.extractPatient}
                                    />
                                }
                                    
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                
            </>
        )
    }
}

export default CustomExtractPage