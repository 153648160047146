import React, { Component } from 'react';
import { 
    ExpansionPanel, 
    ExpansionPanelSummary, 
    ExpansionPanelDetails, 
    Typography, 
    CircularProgress,
    Box,
    Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { postData } from '../../utils/api'
import PATH from '../../constants/api'
import CustomForm from '../CustomForm/CustomForm'
import CustomInput from '../Base/CustomInput/CustomInput'
import CustomButton from '../Base/CustomButton/CustomButton'
import './CustomReviewForm.css'

const PAGE_SIZE = 30

class CustomReviewForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: {
                query: "",
                disabled: false,
                silent: true
            },
            donations: [],
            visibleDonations: [],
            pageIndex: 0,
            formSlot: {}
        }

        this.addItem = this.addItem.bind(this)
        this.searchDonation = this.searchDonation.bind(this)
        this.inputHandler = this.inputHandler.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.openForm = this.openForm.bind(this)
    }

    componentDidMount = async () => {
        try {
            await this.searchDonation()
            this.setState({
                search: {
                    query: this.state.search.query,
                    disabled: false,
                    silent: false
                }
            })
        } catch(e) {
        }
    /*try  {
            const data = await fetchData(PATH.test.path)
            const paged = this.addItem(data.body.entries)
            this.setState(prevState => {
                return {
                    donations: data.body.entries,
                    visibleDonations: prevState.visibleDonations.concat(paged),
                    pageIndex: prevState.pageIndex + PAGE_SIZE
                }
            })
        } catch(error) {
            console.log(error)
        }*/
    }

    addItem = (data) => {
        const tempDonation = data
        let temp = tempDonation.splice(this.state.pageIndex, this.state.pageIndex + PAGE_SIZE)
        return temp
    }

    searchDonation = async () => {
        try  {
            this.setState({
                search: {
                    query: this.state.search.query,
                    disabled: true,
                    silent: this.state.search.silent
                },
                donations: [],
                visibleDonations: [],
                pageIndex: 0,
                formSlot: {}
            })
            const payload = {
                query: this.state.search.query
            }
            console.log("about to post to api path -",PATH.test.search )
            console.log("payload -",payload)            
            const result = await postData(PATH.test.search, payload)
            console.log(result)
            if (result.statusCode < 400){
                let openForm = {}
                for(let i=0; i<result.body.entries.length; i++) {
                    openForm[`open-form-${i}`] = false
                }
                this.setState({
                    search: {
                        query: this.state.search.query,
                        disabled: false,
                        silent: this.state.search.silent
                    },
                    formSlot: openForm,
                    donations: result.body.entries,
                    visibleDonations: result.body.entries,
                })
            }
            else {
                this.setState({
                    search: {
                        query: this.state.search.query,
                        disabled: false,
                        silent: this.state.search.silent
                    },
                    formSlot: {},
                    donations: [],
                    visibleDonations: [],
                })
                if (this.state.search.silent === false){
                    this.props.showAlert('error', result.errorMessage)
                }
            }
        } catch(error) {
            console.log(error)
        }
    }

    inputHandler = (e) => {
        this.setState({
            search: {
                query: e.target.value,
                disabled: this.state.search.disabled,
                silent: this.state.search.silent
            }
        })
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.searchDonation()
        }
    }

    openForm = (event, formId) => {
        event.preventDefault()
        let tempForm = this.state.formSlot
        tempForm[formId] = !tempForm[formId]
        this.setState({
            formSlot: tempForm
        })
    }

    render() {
        return (
            <>

                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Grid container justify="center" alignItems="flex-end">
                            <Grid item xs={7} sm={4} lg={3} className="space-button">
                                <CustomInput 
                                    id="search_input"
                                    required={false}
                                    disabled={this.state.search.disabled}
                                    type="text"
                                    label="Search for a donation id"
                                    name="search_input"
                                    value={this.state.search.query}
                                    onChange={this.inputHandler}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </Grid>
                            <Grid item xs={3} sm={2} lg={1}>
                                <Grid container justify="flex-end">
                                {this.state.search.disabled ? 
                                    <CircularProgress 
                                        size={30}
                                    />
                                : 
                                    <CustomButton 
                                        disabled={this.state.search.disabled}
                                        variant="contained"
                                        color="secondary"
                                        text="Search"
                                        onClick={this.searchDonation}
                                    />
                                }
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <Grid container justify="center" className='review-list'>
                            <Grid item xs={12}>
                                {this.state.visibleDonations.map((item, index) => (
                                    <ExpansionPanel key={`expans_${item.donation_skey}`}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${index}a-content`}
                                            id={index}
                                            onClick={event => this.openForm(event, `open-form-${index}`)}
                                        >
                                            <Typography component={'span'}>{item.donation_bkey}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Box justifyContent="center" flexGrow={1}>
                                                {this.state.formSlot[`open-form-${index}`] ?
                                                    <CustomForm 
                                                        new={false}
                                                        data={item}
                                                        showAlert = {this.props.showAlert}
                                                    />
                                                :
                                                    null
                                                }
                                            </Box>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>  
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
            </>
        )
    }
}

export default CustomReviewForm