const FORM_FIELDS3 = [
    {
        id: 'clinical_use_setting',
        order: 1,
        required: true,
        type: 'select',
        label: "For what type of clinical use will you be entering data?",
        name: "clinical_use_setting",
        visible: true,
        depends: undefined,
        choices: [
            {
                value: "I would like to register a clinical study",
                label: 'I would like to register a clinical study'
            },
            {
                value: "I would like to register a monitored use outside a structured study",
                label: 'I would like to register a monitored use outside a structured study'
            }
        ]    
    },
    {
        id: 'clinical_use_id',
        order: 2,
        required: false,
        type: 'text',
        label: "What is the clinical study identifier (EudraCT N°, ClinicalTrials.gov identifier or other) ?",
        name: "clinical_use_id",
        helperText: "max 255 characters",
        inputProps: {
            maxLength: 255
        },
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_acronym',
        order: 3,
        required: false,
        type: 'text',
        label: "Provide the acronym of the study:",
        name: "clinical_use_acronym",
        helperText: "max 255 characters",
        inputProps: {
            maxLength: 255
        },
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_title',
        order: 4,
        required: false,
        type: 'text',
        label: "Provide the complete title of the study:",
        name: "clinical_use_title",
        helperText: "max 255 characters",
        inputProps: {
            maxLength: 255
        },
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_registration_country',
        order: 5,
        required: false,
        type: 'text',
        label: "In which country is this clinical study registered?",
        name: "clinical_use_registration_country",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_countries',
        order: 6,
        required: false,
        type: 'text',
        label: "Provide all countries in which the clinical study takes place:",
        name: "clinical_use_countries",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_BE_contact_person_email',
        order: 7,
        required: false,
        type: 'text',
        label: "Provide the email address of the contact person of the local BE for this project:",
        name: "clinical_use_BE_contact_person_email",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_type',
        order: 8,
        required: false,
        type: 'select',
        label: "What is the type of study?",
        name: "clinical_use_type",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "Interventional",
                label: 'Interventional'
            },
            {
                value: "Observational",
                label: 'Observational'
            }
        ]
    },
    {
        id: 'clinical_use_allocation',
        order: 9,
        required: false,
        type: 'select',
        label: "What is the allocation of the study?",
        name: "clinical_use_allocation",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "randomized",
                label: 'randomized'
            },
            {
                value: "cross-over",
                label: 'cross-over'
            },
            {
                value: "case control",
                label: 'case control'
            },
            {
                value: "other",
                label: 'other'
            }
        ]
    },
    {
        id: 'clinical_use_allocation_if_other',
        order: 10,
        required: false,
        type: 'text',
        label: "How is the treatment option allocated?",
        name: "clinical_use_allocation_if_other",
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        visible: false,
        depends: [
            {
                id: "clinical_use_allocation",
                answers: [
                    "other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_number_of_arms',
        order: 11,
        required: false,
        type: 'number',
        label: "What is the number of arms in the study?",
        name: "clinical_use_number_of_arms",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_masking',
        order: 12,
        required: false,
        type: 'select',
        label: "What is the masking of the study?",
        name: "clinical_use_masking",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "open label",
                label: 'open label'
            },
            {
                value: "blinded",
                label: 'blinded'
            }
        ]
    },
    {
        id: 'clinical_use_total_enrollment',
        order: 13,
        required: false,
        type: 'number',
        label: "What is the total estimated enrolment?",
        name: "clinical_use_total_enrollment",
        helperText: "The total estimated enrolment is the total number of patients across all study arms to be included in the study.",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_patient_number_per_arm',
        order: 14,
        required: false,
        type: 'number',
        label: "What is the estimated number of patients to be included per arm?",
        name: "clinical_use_patient_number_per_arm",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_experimental_arm',
        order: 15,
        required: false,
        type: 'select',
        label: "What is the intervention/treatment for the experimental arm?",
        name: "clinical_use_experimental_arm",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "convalescent plasma",
                label: 'convalescent plasma'
            },
            {
                value: "other",
                label: 'other'
            }
        ]
    },
    {
        id: 'clinical_use_experimental_arm_if_other',
        order: 16,
        required: false,
        type: 'text',
        label: "Describe the experimental arm:",
        name: "clinical_use_experimental_arm_if_other",
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        visible: false,
        depends: [
            {
                id: "clinical_use_experimental_arm",
                answers: [
                    "other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_convalescent_plasma_has_min_titer',
        order: 16,
        required: false,
        type: 'select',
        label: "Is there a minimum neutralizing titer required for plasma transfusion?",
        name: "clinical_use_convalescent_plasma_has_min_titer",
        visible: false,
        depends: [
            {
                id: "clinical_use_experimental_arm",
                answers: [
                    "convalescent plasma"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'clinical_use_convalescent_plasma_neutralizing_titer',
        order: 17,
        required: false,
        type: 'number',
        label: "What is the neutralizing titer required for plasma transfusion, please indicate x (1/X)?",
        name: "clinical_use_convalescent_plasma_neutralizing_titer",
        visible: false,
        depends: [
            {
                id: "clinical_use_convalescent_plasma_has_min_titer",
                answers: [
                    "True"
                ]
                
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_control_arm',
        order: 18,
        required: false,
        type: 'select',
        label: "What is the clinical treatment in the control arm?",
        name: "clinical_use_control_arm",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "standard of care",
                label: 'standard of care'
            },
            {
                value: "non-specific plasma",
                label: 'non-specific plasma'
            },
            {
                value: "other",
                label: 'other'
            }
        ]
    },
    {
        id: 'clinical_use_control_arm_if_other',
        order: 19,
        required: false,
        type: 'text',
        label: "Describe the clinical treatment in the control arm:",
        name: "clinical_use_control_arm_if_other",
        inputProps: {
            maxLength: 255
        },
        visible: false,
        depends: [
            {
                id: "clinical_use_control_arm",
                answers: [
                    "other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_additional_arm',
        order: 20,
        required: false,
        type: 'select',
        label: "Is there an additional arm?",
        name: "clinical_use_additional_arm",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'clinical_use_additional_arm_info',
        order: 21,
        required: false,
        type: 'text',
        inputProps: {
            maxLength: 255
        },
        helperText: "max 255 characters",
        label: "Provide information on the clinical intervention in the additional arm:",
        name: "clinical_use_additional_arm_info",
        visible: false,
        depends: [
            {
                id: "clinical_use_additional_arm",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_start_date',
        order: 22,
        required: false,
        type: 'date',
        InputLabelProps: {
            shrink: true 
        },
        label: "Specify the study start date (or estimated start date)?",
        name: "clinical_use_start_date",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_primary_completion_date',
        order: 23,
        required: false,
        type: 'date',
        InputLabelProps: {
            shrink: true 
        },
        label: "Specify the estimated primary completion date:",
        name: "clinical_use_primary_completion_date",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_study_completion_date',
        order: 24,
        required: false,
        type: 'date',
        InputLabelProps: {
            shrink: true 
        },
        label: "Specify the estimated study completion date:",
        name: "clinical_use_study_completion_date",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_inclusion_criterias',
        order: 25,
        required: false,
        type: 'multiselect',
        label: "Provide the main inclusion criteria for COVID-19 patients:",
        name: "clinical_use_inclusion_criterias",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: false,
                label: 'Age'
            },
            {
                value: false,
                label: 'Onset of COVID-19 functional signs'
            },
            {
                value: false,
                label: 'Severity as descirbed in the WHO scale'
            },
            {
                value: false,
                label: 'Written informed consent'
            },
            {
                value: false,
                label: 'other'
            }
        ]
    },
    {
        id: 'clinical_use_age_inclusion_criteria_min',
        order: 26,
        required: false,
        type: 'number',
        label: "What is the minimum age for inclusion?",
        name: "clinical_use_age_inclusion_criteria_min",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "Age"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_age_inclusion_criteria_max_defined',
        order: 27,
        required: false,
        type: 'select',
        label: "Is there a maximum age for inclusion?",
        name: "clinical_use_age_inclusion_criteria_max_defined",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "Age"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'clinical_use_age_inclusion_criteria_max',
        order: 28,
        required: false,
        type: 'number',
        label: "What is the maximum age for inclusion?",
        name: "clinical_use_age_inclusion_criteria_max",
        visible: false,
        depends: [
            {
                id: "clinical_use_age_inclusion_criteria_max_defined",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_onset_covid19_inclusion_criteria_min',
        order: 29,
        required: false,
        type: 'number',
        label: "Please specify the minimal number of days that between onset of symptoms and inclusion:",
        name: "clinical_use_onset_covid19_inclusion_criteria_min",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "Onset of COVID-19 functional signs"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_onset_covid19_inclusion_criteria_max_defined',
        order: 30,
        required: false,
        type: 'select',
        label: "Is there a maximum limit on the interval between onset of symptoms and inclusion?",
        name: "clinical_use_onset_covid19_inclusion_criteria_max_defined",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "Onset of COVID-19 functional signs"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'clinical_use_onset_covid19_inclusion_criteria_max',
        order: 31,
        required: false,
        type: 'number',
        label: "Please specify the maximal number of days between onset of the symptoms and inclusion:",
        name: "clinical_use_onset_covid19_inclusion_criteria_max",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "Onset of COVID-19 functional signs"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_severity_inclusion_criteria_min',
        order: 32,
        required: false,
        type: 'number',
        label: "What is the minimum severity grade (WHO-scale) for inclusion?",
        name: "clinical_use_severity_inclusion_criteria_min",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "Severity as descirbed in the WHO scale"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_severity_inclusion_criteria_max',
        order: 33,
        required: false,
        type: 'number',
        label: "What is the maximum severity grade (WHO-Scale) for inclusion?",
        helperText: "If no maximum defined, choose 8",
        name: "clinical_use_severity_inclusion_criteria_max",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "Severity as descirbed in the WHO scale"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_inclusion_criteria_if_other',
        order: 34,
        required: false,
        type: 'text',
        label: "What other inclusion critreria are in place?",
        name: "clinical_use_inclusion_criteria_if_other",
        visible: false,
        depends: [
            {
                id: "clinical_use_inclusion_criterias",
                answers: [
                    "other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_exclusion_criteria',
        order: 35,
        required: false,
        type: 'multiselect',
        label: "Provide the main exclusion criteria for COVID-19 patients?",
        name: "clinical_use_exclusion_criteria",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: false,
                label: 'Age'
            },
            {
                value: false,
                label: 'Pregnancy'
            },
            {
                value: false,
                label: 'Current documented and uncontrolled bacterial infection'
            },
            {
                value: false,
                label: 'Prior severe (grade 3) allergic reactions to plasma transfusion'
            },
            {
                value: false,
                label: 'Participation in any other clinical study of an experimental treatment for COVID-19'
            },
            {
                value: false,
                label: 'Severe chronic kidney disease or requiring dialysis'
            },
            {
                value: false,
                label: 'Hospitalization not requiring ICU admission'
            },
            {
                value: false,
                label: 'Patients who have suspicion or evidence of coinfections'
            },
            {
                value: false,
                label: 'Patients with active treatment for cancer'
            },
            {
                value: false,
                label: 'Receipts of pooled immunoglobulin in past 30 days'
            },
            {
                value: false,
                label: 'Diagnosed viral co-infections'
            },
            {
                value: false,
                label: 'Other'
            }
        ]
    },
    {
        id: 'clinical_use_exclusion_criteria_if_other',
        order: 36,
        required: false,
        type: 'text',
        label: "What other exclusion criteria do you have?",
        name: "clinical_use_exclusion_criteria_if_other",
        helperText: "max 255 characters",
        inputProps: {
            maxLength: 255
        },
        visible: false,
        depends: [
            {
                id: "clinical_use_exclusion_criteria",
                answers: [
                    "Other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episodes_planned_per_patient',
        order: 37,
        required: false,
        type: 'select',
        label: "How many plasma transfusion episodes are planned per patient?",
        name: "clinical_use_transfusion_episodes_planned_per_patient",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            }
        ]
    },
    {
        id: 'clinical_use_transfusion_episode_1_time_point_min',
        order: 38,
        required: false,
        type: 'number',
        label: "What is the time-point for the first transfusion episode (T1)? Please provide the minimum number of days starting from the clinical onset:",
        name: "clinical_use_transfusion_episode_1_time_point_min",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "1"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "2"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_1_time_point_max',
        order: 39,
        required: false,
        type: 'number',
        label: "What is the time-point for the first transfusion episode (T1)? Please provide the maximum number of days starting from clinical onset:",
        name: "clinical_use_transfusion_episode_1_time_point_max",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "1"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "2"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_2_time_point_min',
        order: 40,
        required: false,
        type: 'number',
        label: "What is the time-point for the second transfusion episode (T2)? Please provide the minimum number of days starting from clinical onset:",
        name: "clinical_use_transfusion_episode_2_time_point_min",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "2"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_2_time_point_max',
        order: 41,
        required: false,
        type: 'number',
        label: "What is the time-point for the second transfusion episode (T2)? Please provide the maximum number of days starting from clinical onset:",
        name: "clinical_use_transfusion_episode_2_time_point_max",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "2"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_3_time_point_min',
        order: 42,
        required: false,
        type: 'number',
        label: "What is the time-point for the third transfusion episode (T3)? Please provide the minimum number of days starting from clinical onset:",
        name: "clinical_use_transfusion_episode_3_time_point_min",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_3_time_point_max',
        order: 43,
        required: false,
        type: 'number',
        label: "What is the time-point for the third transfusion episode (T3)? Please provide the maximum number of days starting from clinical onset:",
        name: "clinical_use_transfusion_episode_3_time_point_max",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_4_time_point_min',
        order: 44,
        required: false,
        type: 'number',
        label: "What is the time-point for the fourth transfusion episode (T4)? Please provide the minimum number of days starting from clinical onset:",
        name: "clinical_use_transfusion_episode_4_time_point_min",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_4_time_point_max',
        order: 45,
        required: false,
        type: 'number',
        label: "What is the time-point for the fourth transfusion episode (T4)? Please provide the maximum number of days after clinical onset:",
        name: "clinical_use_transfusion_episode_4_time_point_max",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_transfusion_episode_1_plasma_units',
        order: 46,
        required: false,
        type: 'number',
        label: "How many plasma units are to be transfused at the first transfusion episode (T1)?",
        name: "clinical_use_transfusion_episode_1_plasma_units",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "1"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "2"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            }
        ]
    },
    {
        id: 'clinical_use_transfusion_episode_2_plasma_units',
        order: 47,
        required: false,
        type: 'number',
        label: "How many plasma units are to be transfused at the second transfusion episode (T2)?",
        name: "clinical_use_transfusion_episode_2_plasma_units",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "2"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            }
        ]

    },
    {
        id: 'clinical_use_transfusion_episode_3_plasma_units',
        order: 48,
        required: false,
        type: 'number',
        label: "How many plasma units are to be transfused at the third transfusion episode (T3)?",
        name: "clinical_use_transfusion_episode_3_plasma_units",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "3"
                ]
            },
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            }
        ]
    },
    {
        id: 'clinical_use_transfusion_episode_4_plasma_units',
        order: 49,
        required: false,
        type: 'number',
        label: " How many plasma units are to be transfused at the fourth transfusion episode (T4)?",
        name: "clinical_use_transfusion_episode_4_plasma_units",
        visible: false,
        dependenciesType: 'or',
        depends: [
            {
                id: "clinical_use_transfusion_episodes_planned_per_patient",
                answers: [
                    "4"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            }
        ]
    },
    {
        id: 'clinical_use_has_min_volume_per_episode',
        order: 50,
        required: false,
        type: 'select',
        label: "Is there a minimal volume of plasma to be transfused per episode?",
        name: "clinical_use_has_min_volume_per_episode",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'clinical_use_transfusion_episode_mean_plasma_volume',
        order: 51,
        required: false,
        type: 'number',
        label: "What volume of plasma is targeted per transfusion episode?",
        name: "clinical_use_transfusion_episode_mean_plasma_volume",
        visible: false,
        depends: [
            {
                id: "clinical_use_has_min_volume_per_episode",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_has_min_volume_per_patient',
        order: 52,
        required: false,
        type: 'select',
        label: "Is there a minimal volume of plasma to be transfused per patient?",
        name: "clinical_use_has_min_volume_per_patient",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'clinical_use_total_plasma_volume_per_patient',
        order: 53,
        required: false,
        type: 'number',
        label: "Provide the total plasma volume targeted for transfusion per patient in the study protocol:",
        name: "clinical_use_total_plasma_volume_per_patient",
        visible: false,
        depends: [
            {
                id: "clinical_use_has_min_volume_per_patient",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_primary_outcome_measure',
        order: 54,
        required: false,
        type: 'multiselect',
        label: "What is the primary outcome measure?",
        name: "clinical_use_primary_outcome_measure",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: false,
                label: 'Survival'
            },
            {
                value: false,
                label: 'Serious adverse reactions'
            },
            {
                value: false,
                label: 'Severity (WHO progression Scale)'
            },
            {
                value: false,
                label: 'Improvement in SOFA score'
            },
            {
                value: false,
                label: 'Time from randomization to discharge'
            },
            {
                value: false,
                label: 'Time to oxygen supply independency'
            },
            {
                value: false,
                label: 'Survival without needs of ventilator utilization'
            },
            {
                value: false,
                label: 'Survival without use of immunomodulatory drugs'
            },
            {
                value: false,
                label: 'Duration of hospitalization'
            },
            {
                value: false,
                label: 'Duration of mechanical ventilation'
            },
            {
                value: false,
                label: 'Time to symptoms resolution'
            },
            {
                value: false,
                label: 'Shift to Continuous Positive Airway Pressure (CPAP) ventilation'
            },
            {
                value: false,
                label: 'Referral to a sub-intensive care unit or discharge'
            },
            {
                value: false,
                label: 'Rate of virologic clearance by nasopharyngeal swab'
            },
            {
                value: false,
                label: 'Changes of viral load of SARS-CoV2'
            },
            {
                value: false,
                label: 'Changes in immunglobulin G COVID-19 antibody titer'
            },
            {
                value: false,
                label: 'Changes at the cytokine pattern'
            },
            {
                value: false,
                label: 'other'
            }
        ]
    },
    {
        id: 'clinical_use_primary_outcome_measure_if_other',
        order: 55,
        required: false,
        type: 'text',
        label: "Describe your primary outcome measure:",
        name: "clinical_use_primary_outcome_measure_if_other",
        visible: false,
        depends: [
            {
                id: "clinical_use_primary_outcome_measure",
                answers: [
                    "other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'clinical_use_secondary_outcome_measure',
        order: 56,
        required: false,
        type: 'multiselect',
        label: "What is the secondary outcome measure?",
        name: "clinical_use_secondary_outcome_measure",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a clinical study"
                ]
            }
        ],
        choices: [
            {
                value: false,
                label: 'Survival'
            },
            {
                value: false,
                label: 'Serious adverse reactions'
            },
            {
                value: false,
                label: 'Severity (WHO progression Scale)'
            },
            {
                value: false,
                label: 'Improvement in SOFA score'
            },
            {
                value: false,
                label: 'Time from randomization to discharge'
            },
            {
                value: false,
                label: 'Time to oxygen supply independency'
            },
            {
                value: false,
                label: 'Survival without needs of ventilator utilization'
            },
            {
                value: false,
                label: 'Survival without use of immunomodulatory drugs'
            },
            {
                value: false,
                label: 'Duration of hospitalization'
            },
            {
                value: false,
                label: 'Duration of mechanical ventilation'
            },
            {
                value: false,
                label: 'Time to symptoms resolution'
            },
            {
                value: false,
                label: 'Shift to Continuous Positive Airway Pressure (CPAP) ventilation'
            },
            {
                value: false,
                label: 'Referral to a sub-intensive care unit or discharge'
            },
            {
                value: false,
                label: 'Rate of virologic clearance by nasopharyngeal swab'
            },
            {
                value: false,
                label: 'Changes of viral load of SARS-CoV2'
            },
            {
                value: false,
                label: 'Changes in immunglobulin G COVID-19 antibody titer'
            },
            {
                value: false,
                label: 'Changes at the cytokine pattern'
            },
            {
                value: false,
                label: 'other'
            }
        ]
    },
    {
        id: 'clinical_use_secondary_outcome_measure_if_other',
        order: 57,
        required: false,
        type: 'text',
        label: "Describe your secondary outcome measure:",
        name: "clinical_use_secondary_outcome_measure_if_other",
        visible: false,
        depends: [
            {
                id: "clinical_use_secondary_outcome_measure",
                answers: [
                    "other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'monitored_use_eligibility_criteria',
        order: 56,
        required: false,
        type: 'multiselect',
        label: "What are the main eligibility criteria(s) for monitored use?",
        name: "monitored_use_eligibility_criteria",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a monitored use outside a structured study"
                ]
            }
        ],
        choices: [
            {
                value: false,
                label: 'Age'
            },
            {
                value: false,
                label: 'Severity as descirbed in the WHO scale'
            },
            {
                value: false,
                label: 'Other'
            }
        ]
    },
    {
        id: 'monitored_use_eligibility_criteria_if_other',
        order: 57,
        required: false,
        type: 'text',
        label: "Describe the main eligibility criteria(s) for monitored use:",
        name: "monitored_use_eligibility_criteria_if_other",
        visible: false,
        depends: [
            {
                id: "monitored_use_eligibility_criteria",
                answers: [
                    "Other"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'monitored_use_transfusion_episode_count',
        order: 58,
        required: false,
        type: 'select',
        label: "How many plasma transfusion episodes are planned per patient?",
        name: "monitored_use_transfusion_episode_count",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a monitored use outside a structured study"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            },
            {
                value: "undefined",
                label: 'undefined'
            }
        ]
    },
    {
        id: 'monitored_use_first_plasma_min_days',
        order: 59,
        required: false,
        type: 'number',
        label: "What is the first time-point for administration of plasma? Please provide the minimum number of days after clinical onset:",
        name: "monitored_use_first_plasma_min_days",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a monitored use outside a structured study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'monitored_use_first_plasma_max_days',
        order: 60,
        required: false,
        type: 'number',
        label: "What is the first time-point for administration of plasma? Please provide the maximum number of days after clinical onset:",
        name: "monitored_use_first_plasma_max_days",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a monitored use outside a structured study"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'monitored_use_plasma_units_per_episode',
        order: 61,
        required: false,
        type: 'select',
        label: "How many plasma units are to be transfused during one transfusion episode?",
        name: "monitored_use_plasma_units_per_episode",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a monitored use outside a structured study"
                ]
            }
        ],
        choices: [
            {
                value: "1",
                label: '1'
            },
            {
                value: "2",
                label: '2'
            },
            {
                value: "3",
                label: '3'
            },
            {
                value: "4",
                label: '4'
            }
        ]
    },
    {
        id: 'monitored_use_minimum_volume_per_transfusion_defined',
        order: 62,
        required: false,
        type: 'select',
        label: "Is there a minimal volume of plasma to be transfused per transfusion episode?",
        name: "monitored_use_minimum_volume_per_transfusion_defined",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a monitored use outside a structured study"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'monitored_use_minimum_volume_per_transfusion',
        order: 63,
        required: false,
        type: 'number',
        label: "What is the volume of plasma aimed per transfusion?",
        name: "monitored_use_minimum_volume_per_transfusion",
        visible: false,
        depends: [
            {
                id: "monitored_use_minimum_volume_per_transfusion_defined",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    },
    {
        id: 'monitored_use_minimum_volume_per_patient_defined',
        order: 64,
        required: false,
        type: 'select',
        label: "Is there a minimal volume of plasma to be transfused per patient?",
        name: "monitored_use_minimum_volume_per_patient_defined",
        visible: false,
        depends: [
            {
                id: "clinical_use_setting",
                answers: [
                    "I would like to register a monitored use outside a structured study"
                ]
            }
        ],
        choices: [
            {
                value: "True",
                label: 'yes'
            },
            {
                value: "False",
                label: 'no'
            }
        ]
    },
    {
        id: 'monitored_use_minimum_volume_per_patient',
        order: 65,
        required: false,
        type: 'number',
        label: "What is the total volume to be transfused per patient?",
        name: "monitored_use_minimum_volume_per_patient",
        visible: false,
        depends: [
            {
                id: "monitored_use_minimum_volume_per_patient_defined",
                answers: [
                    "True"
                ]
            }
        ],
        choices: undefined
    }
]

export default FORM_FIELDS3
