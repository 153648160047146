import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, Select, FormControl, FormHelperText} from '@material-ui/core';
import "./CustomSelect.css"

export default class CustomSelect extends Component {
    render() {

        return (
            <FormControl className="container" style={{width: '100%'}}>
                <InputLabel id={`label_${this.props.name}`}>{this.props.label}</InputLabel>
                <Select
                    id={this.props.id} 
                    key={this.props.key}
                    required={this.props.required}
                    labelId={`label_${this.props.name}`}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                >
                    {this.props.choices.map((elem, index) => (
                        <MenuItem key={index} value={elem.value}>{elem.label}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>{this.props.helperText}</FormHelperText>
            </FormControl>
        )
    }
}

CustomSelect.propTypes = {
    id: PropTypes.any,
    required: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    choices: PropTypes.array,
    onChange: PropTypes.func,
};