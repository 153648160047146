
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { AppBar, Toolbar, Typography, Tabs, Tab, Grid, IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Alert from '@material-ui/lab/Alert';
import './CustomAppBar.css'

export default class CustomAppBar extends Component {
    render() {

        return (
            <AppBar position="fixed">
                <Toolbar>
                    <Grid container justify="center">
                        <Grid item xs={12} className="app-bar-color">
                            <Grid container justify="center" className="app-bar-height">
                                <Grid item xs={2}>
                                    <Grid container justify="flex-start" spacing={2} className="app-bar-logout">
                                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.props.handlePressMenu}>
                                            <LocalHospitalIcon />
                                            Switch module
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container justify="center" spacing={2} className="app-bar-logout">
                                        <Typography variant="h6" className="app-bar-logout">
                                            {this.props.moduleName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container justify="flex-end" spacing={2} className="app-bar-logout">
                                        <AmplifySignOut/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
                {this.props.showTabBar !== undefined && this.props.showTabBar ?
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <Tabs
                                value={this.props.value}
                                onChange={this.props.handleChange}
                                aria-label="simple tabs example"
                                centered
                                variant = "fullWidth"
                            >
                                {this.props.tabs.map((elem, index) => (
                                    <Tab label={elem} key={index} />
                                ))}
                            </Tabs>
                        </Grid>
                    </Grid>
                :
                    null
                }
                
                {this.props.showAlert ? 
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <Alert severity={this.props.alertType}>{this.props.alertMessage}</Alert>
                        </Grid>
                    </Grid>
                : 
                    null
                }
            </AppBar>
        )
    }
}

CustomAppBar.propTypes = {
    alertType: PropTypes.string.isRequired,
    alertMessage: PropTypes.string,
    showAlert: PropTypes.bool,
    value: PropTypes.number.isRequired,
    handleChange: PropTypes.func,
    handlePressMenu: PropTypes.func,
    showTabBar: PropTypes.bool,
    tabs: PropTypes.array
};