/*import { withAuthenticator } from '@aws-amplify/ui-react';
import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import CustomTabPanel from './components/Base/CustomTabPanel/CustomTabPanel'
import CustomAppBar from './components/CustomAppBar/CustomAppBar'
import CustomForm from './components/CustomForm/CustomForm'
import CustomTrialForm from './components/CustomTrialForm/CustomTrialForm'
import CustomReviewForm from './components/CustomReviewForm/CustomReviewForm'
import CustomReviewTrialForm from './components/CustomReviewTrialForm/CustomReviewTrialForm'
import CustomUploadHistory from './components/CustomUploadHistory/CustomUploadHistory'
import CustomUploadPatient from './components/CustomUploadPatient/CustomUploadPatient'
import CustomDrawer from './components/CustomDrawer/CustomDrawer'
import AMPLIFY_CONFIG from './utils/amplify'
import { Grid } from '@material-ui/core'
import { DONATION_TAB, TRIAL_TAB} from './constants/tabBar'
import './App.css';


Amplify.configure(AMPLIFY_CONFIG);
export class App extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tabValue: 0,
            alert: {
                alertType: 'info',
                alertMessage: '',
                showAlert: false
            },
            openMenu: false,
            menuChoise: "donation"
        }
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.showAlert = this.showAlert.bind(this)
        this.onClickMenu = this.onClickMenu.bind(this)
        this.changePage = this.changePage.bind(this)
    }

    componentDidMount = () => {
    }

    componentDidUpdate = prevProps => {
    }

    componentWillUnmount = () => {
    }

    handleChangeTab = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    };

    showAlert = (alertType, alertMessage) => {
        this.setState({
            alert: {
                alertType: alertType,
                alertMessage: alertMessage,
                showAlert: true
            }
        })
        setTimeout(() => {
            this.setState({
                alert: {
                    alertType: 'info',
                    alertMessage: '',
                    showAlert: false
                }
            })
        }, 12000)
    }
    
    onClickMenu = (event, openMenu=undefined) => {
        const tempValue = !this.state.openMenu
        this.setState({
            openMenu: openMenu !== undefined ? openMenu : tempValue
        })
    }
    
    changePage = (e, page) => {
        e.preventDefault()
        this.setState({
            menuChoise: page,
            openMenu: !this.state.openMenu
        })
    }

    render() {

        return (
            <>
                <CustomDrawer 
                    onClick={this.onClickMenu}
                    open={this.state.openMenu}
                    onMenuClick={this.changePage}
                />
                <Grid container justify="center">
                    <Grid item xs={12}>
                        {this.state.menuChoise === "donation" && 
                            <>
                                <CustomAppBar 
                                    value={this.state.tabValue}
                                    handleChange={this.handleChangeTab}
                                    alertType={this.state.alert.alertType}
                                    alertMessage={this.state.alert.alertMessage}
                                    showAlert={this.state.alert.showAlert}
                                    handlePressMenu={this.onClickMenu}
                                    tabs={DONATION_TAB}
                                    showTabBar={true}
                                    moduleName="DONATIONS"
                                />
                                <CustomTabPanel
                                    isVisible={this.state.tabValue === 0}
                                >
                                    {this.state.tabValue === 0 ? 
                                        <CustomForm new={true} showAlert={this.showAlert}/>
                                    : null}
                                </CustomTabPanel>
                                <CustomTabPanel
                                    isVisible={this.state.tabValue === 1}
                                >
                                    {this.state.tabValue === 1 ? 
                                        <CustomUploadHistory new={true} showAlert={this.showAlert}/>
                                    : null}
                                </CustomTabPanel>
                                <CustomTabPanel
                                    isVisible={this.state.tabValue === 2}
                                >
                                    {this.state.tabValue === 2 ? 
                                        <CustomReviewForm showAlert={this.showAlert}/>
                                    : null}
                                </CustomTabPanel>
                                <CustomTabPanel
                                    isVisible={this.state.tabValue === 3}
                                >
                                    <Grid container justify="center">
                                        <Grid item xs={12}>
                                            <Grid container justify="center">
                                                {this.state.tabValue === 3 ? 
                                                    <iframe width="800" height="600" src="https://app.powerbi.com/view?r=eyJrIjoiYjI5OGQxZGMtNmQ5Zi00ZDk3LWI2MDMtZDg4NzUzN2VlNmEwIiwidCI6ImIyNGM4YjA2LTUyMmMtNDZmZS05MDgwLTcwOTI2ZjhkZGRiMSIsImMiOjh9" frameBorder={0} allowFullScreen={true} title="be_registration_dashboard"></iframe>
                                                : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                            </>
                        }
                        {this.state.menuChoise === "trial" &&  
                            <>
                                <CustomAppBar 
                                    value={this.state.tabValue}
                                    handleChange={this.handleChangeTab}
                                    alertType={this.state.alert.alertType}
                                    alertMessage={this.state.alert.alertMessage}
                                    showAlert={this.state.alert.showAlert}
                                    handlePressMenu={this.onClickMenu}
                                    tabs={TRIAL_TAB}
                                    showTabBar={true}
                                    moduleName="PATIENTS"
                                />
                                <CustomTabPanel
                                    isVisible={this.state.tabValue === 0}
                                >
                                    {this.state.tabValue === 0 ? 
                                        <CustomTrialForm new={true} showTab={false} showAlert={this.showAlert}/>                                    
                                        : null}
                                </CustomTabPanel>
                                <CustomTabPanel
                                    isVisible={this.state.tabValue === 1}
                                >
                                    {this.state.tabValue === 1 ? 
                                        <CustomUploadPatient new={true} showAlert={this.showAlert}/>
                                    : null}
                                </CustomTabPanel>
                                <CustomTabPanel
                                    isVisible={this.state.tabValue === 2}
                                >
                                    {this.state.tabValue === 2 ? 
                                        <CustomReviewTrialForm showAlert={this.showAlert}/>
                                    : null}
                                </CustomTabPanel>
                            </>
                        }
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withAuthenticator(App);*/

import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import { Authenticator } from "aws-amplify-react";
import AMPLIFY_CONFIG from './utils/amplify'
import './App.css';

import CustomAuthWrapper from './components/CustomAuthWrapper'

Amplify.configure(AMPLIFY_CONFIG);
export class App extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <>
                <Authenticator hideDefault={true} amplifyConfig={AMPLIFY_CONFIG}>
                    <CustomAuthWrapper />
                </Authenticator>
            </>
        )
    }
}
  

export default App;