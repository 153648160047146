import React, { Component } from 'react';
import { getQuestions, getInputValues } from '../../utils/FormUtils'
import CustomInput from '../Base/CustomInput/CustomInput'
import CustomSelect from '../Base/CustomSelect/CustomSelect'
import CustomMultiselect from '../Base/CustomMultiselect/CustomMultiselect'
import CustomButton from '../Base/CustomButton/CustomButton'
import { Box, CircularProgress, Card , Grid } from '@material-ui/core'
import { postData } from '../../utils/api'
import PATH from '../../constants/api'
import PropTypes from 'prop-types';
import $ from 'jquery';

class CustomClinicalUse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            questions: [],
            disabled: false,
            query: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount = () => {
        let questions = JSON.parse(JSON.stringify(getQuestions('clinicaluse')))

        questions.forEach(element => {
            if(!this.props.new) {

                if(element.type==="multiselect"){


                    let arr = this.props.data[element.id].split(";");
                    element['value'] = []
                    element['choices'].forEach(elem => {

                        if(arr.indexOf(elem.label)!==-1){

                            elem.value = true;
                            element['value'] = [...element['value'], {
                                label: elem.label,
                                value: elem.value
                            }]
                        }
                        else 
                            elem.value = false;

                    })

                        
                }
                else {

                    element['value'] = this.props.data[element.id]
                    
                }

                    element['key'] = this.props.data.clinical_use_skey

                if(element.id === 'clinical_use_id' || element.id === 'clinical_use_setting') {
                    element['disabled'] = true
                }
                //calling getInputValues for each to evaluate visibility of questions when data is preloaded in review donations
                //without this, dependent question will not become visible unless you deselect and reselect the dependent answer
                getInputValues(questions, element.id, element.id, element['value'],element.type==="multiselect"?true:false)
            } else {
                element['value'] = ''
            }
        });
        this.setState({
            questions: questions,
        })

        $(document).on("wheel", "input[type=number]", function (e) {
            $(this).blur();
        });
    }

    handleChange = (event) => {
        console.log(this.state)
        if(event.type !== undefined && event.type === 'multiselect') {
            let tempState = this.state.questions
            let targetId = ''
            let targetName = ''
            let targetValue = ''
            if(!this.props.new){
                if(event.id !== undefined) {
                    const splitId = event.id.split('_SPLIT_')
                    targetId = splitId[0]
                }
                const splitName = event.id.split('_SPLIT_')
                targetName = splitName[0]
            } else {
                targetId = event.id
                targetName = event.id
            }
            
            targetValue = event.data.filter(obj => obj.value === true);

            tempState = getInputValues(tempState, targetId, targetName, targetValue, true)

            this.setState({
                questions: tempState
            })

        } else {
            if(event.target.type === 'number' && event.target.value < 0) {
                event.target.value = 0
            }
            let tempState = this.state.questions
            let targetId = ''
            let targetName = ''
            if(!this.props.new){
                if(event.target.id !== undefined) {
                    const splitId = event.target.id.split('_SPLIT_')
                    targetId = splitId[0]
                }
                const splitName = event.target.name.split('_SPLIT_')
                targetName = splitName[0]
            } else {
                targetId = event.target.id
                targetName = event.target.name
            }
            tempState = getInputValues(tempState, targetId, targetName, event.target.value, false)
    
            this.setState({
                questions: tempState
            })
        }
    }

    onSubmit = async e => {
        try {
            e.preventDefault();
            this.setState({
                disabled: true
            })
            const payload = {}
            this.state.questions.forEach(item => { 

                let value="";

                //workaround for multiselect review form not being translated yet
                if(item.type === "multiselect"){
                    if(item.value!==undefined){
                        if(item.value !== ''){
                            item.value.forEach(it=> {
                                value = value.concat(it.label);
                                value = value.concat(";")
                            })
                        }else
                            value=item.value;
                    }
                    else 
                        value = "";
                } else 
                    value=item.value;

                if( item.visible && item.required && value.replace(/\s/g,"") === ""){
                    this.props.showAlert('warning', `Field '${item.label}' is required`)
                    throw new Error(`Field '${item.label}' is required`)
                }
                if (item.type === 'number') {
                    var myRe = /^[0-9]*$/
                    if (!myRe.test(item.value)){
                        this.props.showAlert('warning', `Invalid symbols in number field: ${item.id}`)
                        throw new Error(`Invalid symbols in number field: ${item.id}`)
                        }
                }

                payload[item.id] = item.type === "multiselect"? value:item.value;
                
            })

            payload['new'] = this.props.new


            console.log("Payload -", payload)
            const result = await postData(PATH.test.clinical_use, payload)
            if (result.statusCode === 200) {
                this.props.showAlert('success', result.body)

                if (this.props.new) {

                    this.state.questions.map((element,index) => {
                        element['value'] = ''
                        element.visible = element["depends"] !== undefined || element["transfusionDepends"] !== undefined ? false : true; 
                        if(element.type==="multiselect"){
                            element.choices.map((elem,index) => {
                                elem.value = false;
                            })
                        }
                    });

                    this.setState({
                        disabled: false
                    })
                }
                else {
                    this.setState({
                        disabled: false,
                    })
                }
            }
            else{
                this.setState({
                    disabled: false
                })
                this.props.showAlert('error', result.errorMessage)
            }
        } catch(e) {
            //pass better errors from lambda and pass them to front in alert
            console.log(e)
            this.setState({
                disabled: false
            })
            this.props.showAlert('error', e.message)
        }
    };

    render() {
        return (
            <>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={(!this.props.new ? 12 : 12)} md={(!this.props.new ? 12 : 8)} lg={(!this.props.new ? 12 : 6)}>
                        <Card>
                            {this.state.questions.map((elem, index) => 
                                elem.visible ? 
                                    elem.type === 'select' ? 
                                        <Box 
                                            justifyContent="center" flexGrow={1}
                                            key={index}
                                            className="container-input-form"
                                        >
                                            <CustomSelect 
                                                id={!this.props.new ? `${elem.id}_SPLIT_${this.props.data.donation_skey}` : elem.id} 
                                                required={elem.required}
                                                label={elem.label}
                                                name={!this.props.new ? `${elem.name}_SPLIT_${this.props.data.donation_skey}` : elem.name} 
                                                value={elem.value}
                                                choices={elem.choices}
                                                onChange={this.handleChange}
                                                helperText={elem.helperText}
                                                inputProps={elem.inputProps}
                                                InputLabelProps={elem.InputLabelProps}
                                            />
                                        </Box>
                                    : 

                                    elem.type === 'multiselect' ?
                                        <Box 
                                            justifyContent="center" flexGrow={1}
                                            key={index}
                                            className="container-input-form"
                                        >
                                            <CustomMultiselect 
                                                id={!this.props.new ? `${elem.id}_SPLIT_${this.props.data.donation_skey}` : elem.id} 
                                                label={elem.label}
                                                name={!this.props.new ? `${elem.name}_SPLIT_${this.props.data.donation_skey}` : elem.name} 
                                                choices={elem.choices !== undefined ? elem.choices : []}
                                                onChange={this.handleChange}
                                            />
                                        </Box>
                                    :
                                        <Box 
                                            justifyContent="center" flexGrow={1}
                                            key={index}
                                            className="container-input-form"
                                        >
                                            <CustomInput 
                                                id={!this.props.new ? `${elem.id}_SPLIT_${this.props.data.donation_skey}` : elem.id} 
                                                required={elem.required}
                                                disabled={elem.disabled !== undefined ? (elem.disabled || this.state.disabled) : this.state.disabled }
                                                type={elem.type}
                                                label={elem.label}
                                                name={!this.props.new ? `${elem.name}_SPLIT_${this.props.data.donation_skey}` : elem.name} 
                                                value={elem.value}
                                                helperText={elem.helperText}
                                                onChange={this.handleChange}
                                                inputProps={elem.inputProps}
                                                pattern={elem.pattern}
                                                InputLabelProps={elem.InputLabelProps}
                                            />
                                        </Box>
                                :
                                    null
                            )}
                            <Box className="padding-bottom-card"></Box>
                        </Card >
                    </Grid>
                </Grid>
                <Grid container spacing={2} justify="center" >
                    <Grid item xs={(!this.props.new ? 12 : 6)}>
                        <Grid container justify="center">
                            {this.state.disabled ? 
                                <CircularProgress 
                                    size={30}
                                />
                            :
                                <CustomButton 
                                    variant="contained"
                                    color="secondary"
                                    text={!this.props.new ? "Save" : "Submit"}
                                    disabled={this.state.disabled}
                                    onClick={this.onSubmit}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

CustomClinicalUse.propTypes = {
    new: PropTypes.bool,
    data: PropTypes.object,
    showTab: PropTypes.bool
};

export default CustomClinicalUse;